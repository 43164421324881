import React from 'react';
import { useSelector } from 'react-redux';
import './App.css';
import Loading from './components/Loading/Loading';
import { RootState } from './redux/store';
import AppRouter from './router/Router';

const App = () => {
  const { loading } = useSelector((state: RootState) => state.loading)
  
  return (
    <div className="App">
      <Loading show={loading} />
      <AppRouter />
    </div>
  );
}

export default App;
