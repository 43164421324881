import styled, { css, keyframes } from "styled-components";

const FadeInDownAnimation = keyframes`
  0% { 
    opacity: 0;
    visibility: hidden;
    -webkit-transform: translate(-50%, 100px);
    transform: translate(-50%, 100px);
  }
  100% { 
    opacity: 1; 
    visibility: visible;
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
`;

const FadeOut = keyframes`
  0% { 
    opacity: 1; 
    -webkit-transform: translate(-50%, 0);
    transform: translate(-50%, 0);
  }
  100% { 
    opacity: 0; 
    -webkit-transform:translate(-50%, -100px);
    transform: translate(-50%, -100px);
  }
`;

export const Container = styled.div<{ startAnimation: boolean }>`
  position: relative;
  background-color: #0f071c;
  width: 100%;
  height: 100%;
  cursor: pointer;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
`;

export const OverlayImage = styled(Image)<{ visible: boolean }>`
  position: absolute;
  z-index: 2;
  top: 0;
  left: 0;
  display: ${({ visible }) => (visible ? "block" : "none")};
  cursor: pointer;
`;

export const BulletsContainer = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  bottom: 64px;
  left: 50%;
  z-index: 3;
  gap: 20px;
  transform: translateX(-50%);
`;

export const Bullet = styled.div<{ active: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 16px;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#fff" : "#292530")};
  transition: background-color 0.2s ease-out;
  -webkit-transition: background-color 0.2s ease-out;
  -moz-transition: background-color 0.2s ease-out;
  -o-transition: background-color 0.2s ease-out;
`;

export const logoStyles: React.CSSProperties = {
  position: "absolute",
  top: "48px",
  left: "48px",
};

export const Button = styled.button<{ visible: boolean }>`
  outline: none;
  border: none;
  color: #fff;
  background: transparent;
  font-family: "VrodasFont";
  font-weight: 700;
  border-radius: 4px;
  padding: 8px 18px;
  text-transform: uppercase;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  bottom: 32px;
  left: 50%;
  z-index: 9;
  gap: 20px;
  cursor: pointer;
  transform: translateX(-50%);
  transition: color 0.3s ease-in-out;

  & svg {
    width: 122px !important;
    height: 54px !important;
  }
  & svg path {
    fill: #fff;
    transition: fill 0.3s ease-in-out;
  }

  &:hover {
    color: ${({ theme }) => theme.palette.primary};

    & svg path {
      fill: ${({ theme }) => theme.palette.primary};
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    bottom: 8px;
  }

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    ${({ visible }) =>
      visible
        ? css`
            animation: ${FadeInDownAnimation} 350ms ease-out;
            animation-fill-mode: forwards;
          `
        : css`
            animation: ${FadeOut} 350ms ease-out;
            animation-fill-mode: forwards;
          `};
  }
`;
