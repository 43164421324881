import { useEffect, useRef } from "react";
import {
  BlackContainer,
  ContactContainer,
  Title,
  Square,
  IconsContainer,
  CircleContainer,
  Link,
  Account,
  EmptyLink,
  Bar1,
  BarReveal,
  BackgroundImage,
  BarBottom,
} from "./styles";
import { ReactComponent as InstagramIcon } from "../../../../assets/images/instagram.svg";
import { ReactComponent as VimeoIcon } from "../../../../assets/images/vimeo.svg";
import Reveal from "../../../../components/Reveal/Reveal";
import { ContactProps } from "./types";
import { useResponsive } from "../../../../hooks/useResponsive";

const Contact = ({ isVisible, backgroundImage, barImage1, barImage2 }: ContactProps) => {
  const firstRender = useRef<boolean>(true);
  const { isMobile } = useResponsive();
  const reveal = () => {
    var reveals = document.querySelectorAll(".contact-container .reveal");
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  };

  useEffect(() => {
    if (isVisible && firstRender.current) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible]);

  return (
    <ContactContainer className="contact-container" id="contact-section">
      {backgroundImage && <BackgroundImage crossOrigin="anonymous" src={backgroundImage} alt="background" />}
      {barImage1 && <Bar1 crossOrigin="anonymous" src={barImage1} alt="contact-bar-1" />}
      {barImage2 && <BarReveal delay={isMobile ? 300 : 1000}>
        <BarBottom crossOrigin="anonymous" src={barImage2} alt="contact-bar-2" />
      </BarReveal>}
      <div>
        <Reveal delay={isMobile ? 0 : 250}>
          <Title>LET'S TALK</Title>
        </Reveal>
        <Reveal delay={isMobile ? 100 : 500}>
          <BlackContainer>
            <Link href="mailto:hello@vrodas.com" tabIndex={-1}>
              hello@vrodas.com
            </Link>
            <Square />
            <Link href="#" tabIndex={-1}>
              Buenos Aires (GTM-3)
            </Link>
          </BlackContainer>
        </Reveal>
      </div>
      <Reveal delay={isMobile ? 200 : 750}>
        <IconsContainer>
          <EmptyLink href="https://www.instagram.com/wearevrodas/" tabIndex={-1} target="_blank">
            <CircleContainer>
              <InstagramIcon />
            </CircleContainer>
            <Account>@wearevrodas</Account>
          </EmptyLink>
          <EmptyLink href="https://vimeo.com/vrodas" tabIndex={-1} target="_blank">
            <CircleContainer>
              <VimeoIcon />
            </CircleContainer>
            <Account>/vrodas</Account>
          </EmptyLink>
        </IconsContainer>
      </Reveal>
    </ContactContainer>
  );
};

export default Contact;
