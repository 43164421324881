import styled, { css } from "styled-components";

export const TitleContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  margin-bottom: 0;
  background-color: #0f071c;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  ${({ theme: { breakpoints, spacing } }) => css`
    padding: 28px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 24px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 20px ${spacing.md}px;
      margin-top: 128px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 16px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 12px ${spacing.xs}px;
      justify-content: center;
    }
  `}
`;

export const Title = styled.h3`
  text-align: left;
  font-size: 30px;
  margin: 0;
  line-height: 24px;
  padding-top: 4px;
  color: #fff;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      font-size: 24px;
      line-height: 18px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      font-size: 20px;
      line-height: 16px;
    }
  `}
`;

export const Slash = styled.div`
  width: 6px;
  min-width: 6px;
  height: 22px;
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.primary};
  transform: skewX(-12deg);
  margin-right: 10px;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      width: 5px;
  min-width: 5px;
      height: 17px;
      margin-right: 8px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      height: 14px;
      width: 4px;
  min-width: 4px;
      margin-right: 6px;
    }
  `}
`;

export const Grid = styled.div`
  position: relative;
  display: block;
  margin: 0 auto;
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  background-color: #0f071c;

  & > div {
    min-height: 300px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ButtonContainer = styled.div`
  width: 100%;
  margin-top: 32px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`;
