import { MenuOptionEnum } from "../components/Navbar/types";

export const NAVBAR_HEIGHT = 60;
export const INITIAL_LOADING_TIME = 700;
export const VIDEO_ASPECT_RELATION = 640/360;
export const TRANSITION_TIME = 700;

export const MENU_OPTIONS = [
  {
    id: MenuOptionEnum.HomeWorks,
    label: "WORK",
  },
  {
    id: MenuOptionEnum.WeDo,
    label: "WE DO",
  },
  {
    id: MenuOptionEnum.WeAre,
    label: "WE ARE",
  },
  {
    id: MenuOptionEnum.Contact,
    label: "CONTACT",
  },
];