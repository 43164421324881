import { useEffect, useRef } from "react";
import {
  WeDoContainer,
  Block,
  WeDo as WeDoTitle,
  Title,
  Background,
  Content,
  ListContainer,
  ItemContainer,
  Slash,
  Item,
  Button,
} from "./styles";
import { WeDoProps } from "./types";
import Reveal from "../../../../components/Reveal/Reveal";
import { useResponsive } from "../../../../hooks/useResponsive";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { actions } from "../../../../redux/loading";
import { delayMs } from "../../../../utils/utils";
import { INITIAL_LOADING_TIME } from "../../../../utils/constants";

const WeDo = ({ isVisible, blocks, backgroundImage }: WeDoProps) => {
  const firstRender = useRef<boolean>(true);
  const { isMobile } = useResponsive();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const onExplore = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate("/work");
  };

  const reveal = () => {
    var reveals = document.querySelectorAll(".we-do-container .reveal");
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  };

  useEffect(() => {
    if (isVisible && firstRender.current && blocks?.length > 0) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, blocks]);

  const texts = blocks.map((block) => ({
    title: block.titulo,
    items: block.cuerpo.split("\r\n"),
  }));
  const leftTexts = texts.filter((_, i) => !(i % 2));
  const rightTexts = texts.filter((_, i) => i % 2);

  const renderList = (title: string, items: string[], index: number) => (
    <Block key={`${title}-${index}`}>
      <Reveal delay={(isMobile ? 0 : 250) * (index + 1)}>
        <Title bold>{title}</Title>
      </Reveal>
      <Reveal delay={(isMobile ? 100 : 500) * (index + 1)}>
        <ListContainer>
          {items.map((item, index) => (
            <ItemContainer key={index}>
              <Slash />
              <Item>{item}</Item>
            </ItemContainer>
          ))}
        </ListContainer>
      </Reveal>
    </Block>
  );

  return (
    <WeDoContainer className="we-do-container" id="we-do-section">
      {backgroundImage && <Background src={backgroundImage} alt="we-do-background" crossOrigin="anonymous" />}

      <Block>
        <Reveal delay={isMobile ? 0 : 250}>
          <WeDoTitle className="title">we do</WeDoTitle>
        </Reveal>
      </Block>
      <Content>
        <div>{leftTexts.map((block, index) => renderList(block.title, block.items, index))}</div>
        <div>{rightTexts.map((block, index) => renderList(block.title, block.items, index))}</div>
      </Content>
      <Reveal delay={isMobile ? 200 : 500}>
        <Button onClick={onExplore}>Explore our work</Button>
      </Reveal>
    </WeDoContainer>
  );
};

export default WeDo;
