import styled, { css } from "styled-components";
import Navbar from "../Navbar/Navbar";

export const CustomNavbar = styled(Navbar)`
  width: 100%;

  & > div:nth-child(2){
    margin-right: 0;
  }

  & svg {
    margin-left: -12px !important;
  }

  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0 ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0 ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0 ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0 ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0 ${spacing.xs}px;
    }

  `}
`;