import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT } from "../../utils/constants";

export const ButtonContainer = styled.button`
  position: fixed;
  outline: none;
  border: none;
  cursor: pointer;
  top: ${NAVBAR_HEIGHT + 16}px;
  padding: 0;
  background-color: transparent;
  z-index: 999;

  ${({ theme: { spacing, breakpoints } }) => css`
    right: ${spacing.xl}px;

   
    @media (max-width: ${breakpoints.sm}px) {
      right: ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      right: ${spacing.xs}px;
    }

  `}
`;
