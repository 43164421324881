import React from "react";
import { SlideStyles } from "./styles";
import { DragSlideProps } from "./types";

const DragSlide = ({ child, sliderWidth, sliderHeight }: DragSlideProps) => {
  return (
    <SlideStyles
      sliderWidth={sliderWidth}
      sliderHeight={sliderHeight}
      className="SlideStyles"
    >
      <div
        unselectable="on"
        className="slide-inner"
        onDragStart={(e) => {
          e.preventDefault();
          e.stopPropagation();
          return false;
        }}
      >
        {child}
      </div>
    </SlideStyles>
  );
};

export default DragSlide;
