import { useCallback, useMemo } from "react";
import { useCaseWork } from "./useCaseWork";
import {
  Container,
  TopHeader,
  WorkContent,
  FrontImage,
  // Client,
  WorkName,
  WorkType,
  TopHeaderImage,
} from "./styles";
import { Helmet } from "react-helmet";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { CaseWorkType } from "../../interfaces/work";
import { WorkHeader } from "../../components/WorkHeader/WorkHeader";
import { WorkItem } from "../../components/WorkHeader/types";
import OtherProjects from "../../components/OtherProjects/OtherProjects";
import Block from "./components/Block/Block";
import { CustomNavbar } from "../../components/WorkNavbar/WorkNavbar";
import Reveal from "../../components/Reveal/Reveal";
import ShareButton from "../../components/ShareButton/ShareButton";

const items: WorkItem[] = [
  { key: "compania", label: "Client" },
  { key: "servicios", label: "Services" },
  { key: "año", label: "Year" },
];

export const CaseWork = () => {
  const { width, containerRef, work, onOptionClick } = useCaseWork();

  const workItems = useMemo(() => {
    if (!work) return [];
    return items.map((item) => ({
      ...item,
      value: (work[item.key as keyof CaseWorkType] as string) || "",
    }));
  }, [work]);

  const renderBlocks = useCallback(() => {
    return work?.bloques?.map((block, index) => <Block width={width} key={index} block={block} />);
  }, [work?.bloques, width]);

  const title = `VRØDAS | Case Study - ${work?.nombre || ""}`
  
  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={work?.descripcion_en || ''} />
        <meta name="thumbnail" content={work?.thumb} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music video" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={work?.thumb} />
        <meta property="og:description" content={work?.descripcion_en || ''} />
        <meta property="og:site_name" content="VRØDAS" />
      </Helmet>
      <ShareButton title={title} text={work?.descripcion_en || ""} url={window.location.href} />
      <CustomNavbar animated={false} fixed actualOption={MenuOptionEnum.HomeWorks} onOptionClick={onOptionClick} />
      <Container ref={containerRef} className="case-work">
        <TopHeader>
          <TopHeaderImage id="image-header" src={work?.fondo_top} alt={work?.nombre} />
          <Reveal offset={-25} delay={400}>
            {/* <Client>{work?.artista}</Client> */}
            <WorkName>{work?.nombre}</WorkName>
            <WorkType>CASE STUDY</WorkType>
          </Reveal>
        </TopHeader>
        <WorkHeader description={work?.descripcion_en || ""} items={workItems} />
        <WorkContent>
          <Reveal>
            <FrontImage src={work?.imagen_principal} alt={work?.nombre || ""} />
          </Reveal>
        </WorkContent>
        {renderBlocks()}
        <OtherProjects works={work?.otros_proyectos || []} />
      </Container>
    </>
  );
};

export default CaseWork;
