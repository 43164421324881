import styled from "styled-components";

export const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  height: 42px;
  width: 148px;
  background-color: ${( {theme} ) => theme.palette.text};
  cursor: pointer;
  z-index: 99;

  & svg {
    margin: auto 0;
    width: 148px !important;
    height: 56px !important;

    @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
      height: 48px !important;
    }
  }
`;

export const LogoJson = styled.div`
  display: flex;

    /* 
      & path {
        transition: fill 0.4s ease-in-out;
      }

    &:hover {
        path {
        fill: ${({ theme }) => theme.palette.primary};
        stroke: ${({ theme }) => theme.palette.primary}
      }
        } 
     */
    



`;
