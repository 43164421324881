import { ButtonContainer } from "./styles";
import { ReactComponent as ShareIcon } from "../../assets/images/boton_share.svg";
import { useResponsive } from "../../hooks/useResponsive";
import { useContext } from "react";
import { ThemeContext } from "styled-components";
import { RWebShare } from "react-web-share";
import { ShareButtonProps } from "./types";

const ShareButton = (props: ShareButtonProps) => {
  const { breakpoints } = useContext(ThemeContext);
  const { width } = useResponsive();

  const onShare = () => {
    let copyButton: HTMLButtonElement;

    const onCopyClick = (e: MouseEvent) => {
      navigator.clipboard.writeText(props.url);
    };

    setTimeout(() => {
      const twitterButton = document.querySelector('.rws-container [aria-label="twitter"]') as HTMLButtonElement;
      if (twitterButton) {
        twitterButton.style.background = "#fff";
        twitterButton.innerHTML = `
          <svg version="1.1" id="svg5" xmlns:svg="http://www.w3.org/2000/svg"  transform="scale(1.5)"
            xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 1668.56 1221.19"
            style="enable-background:new 0 0 1668.56 1221.19;" xml:space="preserve">
            <g id="layer1" transform="translate(52.390088,-25.058597)">
              <path id="path1009" d="M283.94,167.31l386.39,516.64L281.5,1104h87.51l340.42-367.76L984.48,1104h297.8L874.15,558.3l361.92-390.99
                h-87.51l-313.51,338.7l-253.31-338.7H283.94z M412.63,231.77h136.81l604.13,807.76h-136.81L412.63,231.77z" fill="#000"/>
            </g>
          </svg>
        `;
      }

      copyButton = document.querySelector('.rws-container [aria-label="copy"]') as HTMLButtonElement;
      copyButton.addEventListener("click", onCopyClick);
    }, 0);

    return () => {
      if (copyButton) copyButton.removeEventListener("click", onCopyClick);
    };
  };

  if (width > breakpoints.sm) return null;

  return (
    <ButtonContainer onClick={onShare}>
      <RWebShare
        data={props}
        sites={["facebook", "twitter", "whatsapp", "copy"]}
      >
        <ShareIcon width={24} />
      </RWebShare>
    </ButtonContainer>
  );
};

export default ShareButton;
