import { createSelector, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DetailedWorkType, Work } from "../../interfaces/work";
import { RootState } from "../store";
import { WorkState } from "./types";

const initialState: WorkState = {
  works: [],
  detailedWorks: [],
};

export const workSlice = createSlice({
  name: "works",
  initialState,
  reducers: {
    setWorks: (state: WorkState, action: PayloadAction<Work[]>) => {
      state.works = action.payload;
    },
    setDatailedWork: (
      state: WorkState,
      action: PayloadAction<Work>
    ) => {
      const work = action.payload;
      state.works = [...state.works, work];
    },
  },
});

export const existingWork = (workId: number, workType: string) =>
  createSelector(
    (state: RootState) => state.works.detailedWorks,
    (works: DetailedWorkType[]) =>
      works.some((w) => w.id === workId && w.tipo === workType)
  );

export const actions = workSlice.actions;

export default workSlice.reducer;
