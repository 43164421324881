import styled, { css } from "styled-components";

export const WorkSectionContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin: 48px 0;

  ${({ theme: { breakpoints, spacing } }) => css`
    margin-left: ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      margin-left: ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      margin: 32px 0;
      margin-left: ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      margin-left: ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      margin-left: ${spacing.xs}px;
      justify-content: center;
    }
  `}
`;

export const Slash = styled.div`
  width: 8px;
  min-width: 8px;
  height: 31px;
  margin-top: 6px;
  display: inline-block;
  background-color: ${({ theme }) => theme.palette.primary};
  transform: skewX(-12deg);
  margin-right: 12px;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.lg}px) {
      height: 26px;
      margin-top: 8px;
      width: 7px;
      min-width: 7px;
    }

    @media (max-width: ${breakpoints.md}px) {
      height: 20px;
      width: 5px;
      min-width: 5px;
      margin-top: 7px;
      margin-right: 8px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      height: 14px;
      width: 4px;
      min-width: 4px;
      margin-top: 8.5px;
      margin-right: 6px;
    }
  `}
`;

export const Title = styled.h3`
  text-transform: uppercase;
  font-size: 45px;
  line-height: 42px;
  padding-top: 4px;
  text-align: left;
  margin: 0;
  color: #0f071c;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.lg}px) {
      font-size: 39px;
      line-height: 36px;
      padding-top: 6px;
    }

    @media (max-width: ${breakpoints.md}px) {
      font-size: 28px;
      line-height: 26px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      font-size: 19px;
      line-height: 18px;
      padding-top: 8px;
    }
  `}
`;
