import React, { useCallback, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Grid, ViewAll, ViewAllText, HomeWorksContainer } from "./styles";
import WorkThumb from "../../../../components/WorkThumb/WorkThumb";
import "./animation.css";
import { WorksProps } from "./types";
import { Work } from "../../../../interfaces/work";
import { actions } from "../../../../redux/loading";
import { useDispatch } from "react-redux";
import { INITIAL_LOADING_TIME } from "../../../../utils/constants";
import { delayMs } from "../../../../utils/utils";

const Works = ({ isVisible, works }: WorksProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const gridRef = useRef<HTMLDivElement | null>(null);
  const itemsRef = useRef<HTMLDivElement[]>([]);

  const goToWorks = async () => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    navigate("/work");
  }

  const renderItems = useCallback(() => {
    return works.map((work: Work, i: number) => (
      <WorkThumb key={i} work={work} />
    ));
  }, [works]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, works.length);
  }, [works]);

  return (
    <HomeWorksContainer id="home-works-section">
      <Grid className="grid" ref={gridRef} show={isVisible || false}>
        {renderItems()}
        <ViewAll onClick={goToWorks}>
          <ViewAllText>VIEW ALL WORK</ViewAllText>
        </ViewAll>
      </Grid>
    </HomeWorksContainer>
  );
};

export default React.memo(Works);
