import { useCallback, useRef, useEffect } from "react";
import lottie from "lottie-web";
import LogoAnim from "../../assets/logo.json";
import { LogoContainer, LogoJson } from "./styles";
import { LogoProps } from "./types";

const Logo = ({ styles, onClick }: LogoProps) => {
  const handleClick = useCallback(() => onClick && onClick(), [onClick]);
  
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    lottie.loadAnimation({
      name:'logo',
      container: container.current!,
      renderer: "svg",
      loop: false,
      autoplay: false,
      animationData: LogoAnim
    });

    return () => {
      lottie.destroy('logo');
    };
  }, []);
  
  return (
    <LogoContainer onClick={handleClick} style={styles}>
      <LogoJson
        ref={container}
        onMouseEnter={() => lottie.play()}
        onMouseLeave={() => lottie.stop()}
      />
    </LogoContainer>
  );
};

export default Logo;
