import styled, { css, keyframes } from "styled-components";
import { motion } from "framer-motion";

const FadeInDown = keyframes`
  0% { 
    /* -moz-transform: translateY(20px);
    -o-transform: translateY(20px);
    -webkit-transform: translateY(20px); */
    transform: translateY(20px);
  }
  100% { 
    /* -moz-transform: translateY(0);
    -o-transform: translateY(0);
    -webkit-transform: translateY(0); */
    transform: translateY(0);
  }
`;

export const WorkInfo = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  visibility: hidden;
  opacity: 0;
  padding: 32px;
  background-color: transparent;
 //  will-change: transform;
`;

export const GridItem = styled(motion.div)`
  width: 100%;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  min-height: 160px;
  max-height: 340px;

  video,
  img {
    transition: transform 0.3s ease-out;
    -webkit-transition: transform 0.3s ease-out;
    -moz-transition: transform 0.3s ease-out;
    -o-transition: transform 0.3s ease-out;
   //  will-change: transform;
  }

  &:hover {
    ${WorkInfo} {
      visibility: visible;
      opacity: 1;
      transition: all 550ms ease;
      -webkit-transition: all 550ms ease;
      -moz-transition: all 550ms ease;
      -o-transition: all 550ms ease;
      animation: ${FadeInDown} 650ms ease;
    }

    video,
    img {
      filter: grayscale(100%) brightness(15%) sepia(100%) hue-rotate(-50deg) saturate(800%) contrast(0.9);
      /* -webkit-transform: scale(1.1);
      -moz-transform: scale(1.1);
      -o-transform: scale(1.1); */
      transform: scale(1.1);
      // will-change: transform;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    min-height: 260px;
  }
`;

export const WorkName = styled.h2`
  font-size: 36px;
  line-height: 28px;
  margin: 0;
  width: 100%;
  text-align: center;
  text-transform: lowercase;
  font-family: "VrodasFontTitle";
  font-weight: 400;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.palette.primary};
`;

export const WorkArtist = styled.h4`
  font-size: 18px;
  line-height: 18px;
  margin: 0;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  color: #fff;
  margin-bottom: 8px;
`;

export const WorkType = styled.h5`
  font-size: 14px;
  font-weight: 400;
  color: white;
  width: 100%;
  text-align: center;
  text-transform: uppercase;
  margin: 24px 0 0 0;
`;

export const Video = styled.video<{ visible: boolean }>`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  object-fit: cover;
  transition: opacity 0.3 ease-in-out;
  -webkit-transition: opacity 0.3 ease-in-out;
  -moz-transition: opacity 0.3 ease-in-out;
  -o-transition: opacity 0.3 ease-in-out;

  ${({ visible }) => {
    if (visible) {
      return css`
        opacity: 1;
        visibility: visible;
      `;
    }

    return css`
      opacity: 0;
      visibility: hidden;
    `;
  }}
`;

export const Image = styled.img`
  position: absolute;
  top: -2px;
  left: -2px;
  width: calc(100% + 4px);
  height: calc(100% + 4px);
  object-fit: cover;
`;

export const NewIconContainer = styled.div`
  display: flex;
  position: absolute;
  z-index: 999;
  top: 10px;
  left: 10px;
  height: 20px;
  width: 50px;
`;

export const Color = styled.span`
  color: ${({ theme }) => theme.palette.primary};
`;
