import styled, { css, keyframes } from "styled-components";

const FadeOutUpAnimation = keyframes`
  0% { 
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
  100% { 
    opacity: 0;
    display: none;
    transform: translate3d(0, -128px, 0);
  }
`;

const FadeInDownAnimation = keyframes`
  0% { 
    opacity: 0;
    display: block;
    transform: translate3d(0, -64px, 0);
  }
  100% { 
    opacity: 1; 
    transform: translate3d(0, 0, 0);
  }
`;

export const Bar = styled.div`
  width: 5px;
  height: 36px;
  display: inline-block;
  background-color: white;
  transform: skewX(-10deg);
  margin-left: 8px;
  transition: transform 300ms ease-out;
  -webkit-transition: transform 300ms ease-out;
  -moz-transition: transform 300ms ease-out;
  -o-transition: transform 300ms ease-out;
`;

export const IconContainer = styled.div<{ hide: boolean }>`
  position: fixed;
  top: 48px;
  right: 80px;
  z-index: 99;
  ${({ hide }) =>
    hide
      ? css`
          animation: ${FadeOutUpAnimation} 450ms ease-out 100ms;
          animation-fill-mode: forwards;
        `
      : css`
          opacity: 0;
          animation: ${FadeInDownAnimation} 250ms ease-out 300ms;
          animation-fill-mode: forwards;
        `}

  &:hover {
    cursor: pointer;

    & > ${Bar}:first-child {
      transform: skewX(-10deg) translateY(4px);
    }

    & > ${Bar}:last-child {
      transform: skewX(-10deg) translateY(-4px);
    }
  }
`;
