import styled, { css } from "styled-components";

export const SlideStyles = styled.div<{
  sliderWidth: number;
  sliderHeight: number;
}>`
  transition: transform 0.2s ease-out;
  -webkit-transition: transform 0.2s ease-out;
  -moz-transition: transform 0.2s ease-out;
  -o-transition: transform 0.2s ease-out;

  img {
    height: 100%;
    max-height: 75vh;
    //min-height: 75vh;
    max-width: 95vw;
    width: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
    user-select: none;
    margin-right: 20px;
    object-fit: contain;
  }

  ${({ theme: { breakpoints, spacing }, sliderWidth }) => css`
    /* @media (max-width: ${breakpoints.lg}px) {
      img {
        // max-width: ${sliderWidth - 2 * spacing.lg}px; 
        max-height: 60vh;
        min-height: 60vh;
      }
    } */

    /* @media (max-width: ${breakpoints.md}px) {
      img {
        // max-width: ${sliderWidth - 2 * spacing.md}px;
        max-height: 60vh;
        min-height: 60vh;
      }
    }  */

    @media (max-width: ${breakpoints.sm}px) {
      img {
        /* max-width: ${sliderWidth - 2 * spacing.sm}px; */
        max-height: 50vh;
        /* min-height: 50vh; */
        /* object-fit: cover; */
        margin-right: 8px;
      }
    }

    @media (max-width: ${breakpoints.xs}px) {
      img {
        /* max-width: ${sliderWidth - 2 * spacing.xs}px; */
        max-height: 30vh;
        /* min-height: 30vh; */
        /* object-fit: cover; */
        margin-right: 4px;
      }
    }
  `}
`;
