import styled from "styled-components";
import { NAVBAR_HEIGHT } from "../../../../utils/constants";

export const WeDoContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  padding: 0 172px;
  padding-top: ${NAVBAR_HEIGHT}px;
  background-color: #fff;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 64px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 128px;
    padding-top: ${NAVBAR_HEIGHT}px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 64px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 64px 32px;
    gap: 32px;
  }
`;

export const Block = styled.div`
  width: 100%;
  position: relative;
  z-index: 1;
`;

export const Content = styled.div`
  max-width: 1024px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  gap: 64px;
  z-index: 1;
  margin: 0 auto;
 
  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 0;
    flex-direction: column;
  }
`

export const Background = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const WeDo = styled.h1`
  font-size: 160px;
  line-height: 120px;
  font-weight: 400;
  letter-spacing: -10px;
  font-family: "VrodasFontTitle";
  color: #fff;
  margin: 0;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 120px;
    line-height: 100px;
    letter-spacing: -8px;
    padding-top: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 80px;
    line-height: 80px;
    letter-spacing: -4px;
  }
`;

export const Title = styled.p<{ bold?: boolean }>`
  font-size: 34px;
  line-height: 40px;
  color: #fff;
  margin: 12px 0;
  z-index: 1;
  white-space: pre-line;
  text-align: left;
  margin-top: 32px;
  font-weight: ${({ bold }) => (bold ? "700" : "400")};



  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 21px;
    line-height: 26px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 20px;
    line-height: 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 18px;
    line-height: 20px;
    margin-bottom: 16px;
  }
`;


export const ListContainer = styled.div``;

export const Slash = styled.div`
  width: 3px;
  height: 12px;
  margin-top: -2px;
  display: inline-block;
  background-color: rgb(255,197,210);
  transform: skewX(-24deg);
  margin-right: 8px;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
   height: 11px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    height: 10px;
    width: 2px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    height: 9px;
  }
`;

export const ItemContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 0px;
  margin-bottom: 8px;
`

export const Item = styled.p`
  margin: 0;
  color: rgb(255,197,210);;
  font-size: 18px;
  line-height: 20px;
  text-transform: uppercase;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 16px;
    line-height: 18px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
    line-height: 16px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 13px;
    line-height: 14px;
  }
`

export const Button = styled.button`
  outline: none;
  border: none;
  background-color: ${({ theme }) => theme.palette.text};
  color: #fff;
  font-family: "VrodasFont";
  font-weight: 700;
  border-radius: 4px;
  padding: 11px 18px 8px 18px;
  font-size: 20px;
  text-transform: uppercase;
  z-index: 1;
  cursor: pointer;
  transition: color 0.2s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 14px;
  }
`