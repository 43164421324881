/* eslint-disable no-restricted-globals */
import React, {
  useRef,
  useEffect,
  useCallback,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import { useTheme } from "styled-components";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import { TRANSITION_TIME } from "../../utils/constants";
import { ScrollContainer } from "./styles";
import { ScrollSectionType, ViewportDimensionType } from "./types";

type ScrollDirType = "down" | "up";
type ScrollModeType = "normal" | "section";
const SCROLL_TRHESHOLD = 60;

const ScrollSection = forwardRef((props: ScrollSectionType, ref) => {
  const {
    id,
    fullSize,
    children,
    direction = "vertical",
    onSectionChanged,
    onAnimationEnd,
  } = props;
  const { breakpoints } = useTheme();

  const scrollContainerRef = useRef<HTMLDivElement | null>(null);
  const holdRef = useRef<boolean>(false);
  const mode = useRef<ScrollModeType>("section");
  const transition = useRef<boolean>(true);
  const transform = useRef<string>("");
  const move = useRef<ScrollDirType>("down");
  const currentSection = useRef<number>(0);
  const currentDelta = useRef<number>(0);
  useScrollAnimation(
    document.querySelector("#root"),
    "scroll-section",
    mode.current === "section"
  );

  const [viewport, setViewport] = useState<ViewportDimensionType>({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const pnls = React.Children.count(children);

  useImperativeHandle(ref, () => ({
    scroll: (section: number, effect?: boolean, delta?: number) =>
      manualScroll(section, effect, delta),
  }));

  const setSectionPosition = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.webkitTransform = 'translateZ(0)';
      scrollContainerRef.current.style.transform = transform.current;
    }
  };

  const animation = useCallback(() => {
    setSectionPosition();
    if (scrollContainerRef.current) requestAnimationFrame(animation);
  }, []);

  const transitionOn = () => {
    transition.current = true;
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.transition = `transform ${TRANSITION_TIME}ms cubic-bezier(.79,.19,.18,.83)`;
    }
  };

  const transitionOff = () => {
    transition.current = false;
    if (scrollContainerRef.current) {
      scrollContainerRef.current.style.transition = `none`;
    }
  };

  const getTranslateOffset = useCallback(
    (index: number, height = viewport.height, width = viewport.width) => {
      const offset = direction === "vertical" ? height : width;
      const shift = -1 * index * offset;
      const dir = direction === "vertical" ? "Y" : "X";
      currentDelta.current = shift;
      return `translate${dir}(${shift}px)`;
    },
    [direction, viewport.height, viewport.width]
  );

  const manualScroll = (sectionNumber: number, effect: boolean = true, delta: number = 0) => {
    if (!scrollContainerRef.current || holdRef.current) return;
    
    if (effect) {
      transitionOn();
      currentSection.current = sectionNumber;
      holdRef.current = true;

      const dir = direction === "vertical" ? "Y" : "X";
      if(delta) {
        transform.current = `translate${dir}(${currentDelta.current + delta}px)`;
        currentDelta.current = currentDelta.current + delta;
      } else{
        transform.current = getTranslateOffset(sectionNumber);
      }
      const frame = requestAnimationFrame(animation);
      setSectionPosition();

      setTimeout(() => {
        holdRef.current = false;
        if (frame) cancelAnimationFrame(frame);
        if (onAnimationEnd) onAnimationEnd();
      }, TRANSITION_TIME);
    } else {
      transitionOff();
      currentSection.current = sectionNumber;
      if(delta) {
        const dir = direction === "vertical" ? "Y" : "X";
        transform.current = `translate${dir}(${currentDelta.current + delta}px)`;
        currentDelta.current = currentDelta.current + delta;
      } else{
        transform.current = getTranslateOffset(sectionNumber);
      }
      setSectionPosition();
      if (onAnimationEnd) onAnimationEnd();
    }
  };

  const wheel = useCallback(
    (e: WheelEvent) => {
      if (mode.current === "normal" || direction !== "vertical") return;
      move.current = e.deltaY < 0 ? "up" : "down";
      e.stopPropagation();
    },
    [direction]
  );

  const translate = useCallback(
    (width: number, height: number) => {
      if (!scrollContainerRef.current || holdRef.current) return;

      if (transition.current) holdRef.current = true;

      transform.current = getTranslateOffset(
        currentSection.current,
        height,
        width
      );
      const frame = requestAnimationFrame(animation);
      setSectionPosition();

      if (transition.current) {
        setTimeout(() => {
          holdRef.current = false;
          if (frame) cancelAnimationFrame(frame);
          if (onAnimationEnd) onAnimationEnd();
        }, TRANSITION_TIME);
      }
    },
    [getTranslateOffset, animation, onAnimationEnd]
  );

  const scroll = useCallback(
    (e: WheelEvent) => {
      if (holdRef.current || mode.current === "normal") return;
      e.stopPropagation();

      if (Math.abs(e.deltaY) < SCROLL_TRHESHOLD) return;

      const scdir = move.current;
      const top = scdir === "up" && currentSection.current === 0;
      const bottom = scdir === "down" && currentSection.current >= pnls - 1;
      if (top || bottom) return;

      if (scdir === "up") {
        currentSection.current = currentSection.current - 1;
      } else if (scdir === "down") {
        currentSection.current = currentSection.current + 1;
      }

      transitionOn();
      translate(viewport.width, viewport.height);

      if (onSectionChanged) onSectionChanged(currentSection.current);
    },
    [pnls, translate, viewport.width, viewport.height, onSectionChanged]
  );

  const setMode = useCallback(
    (initial?: boolean) => {
      const body = document.documentElement;
      const root = document.getElementById("root");
      if (!body || !root) return;

      const prevMode = mode.current;
      if (window.innerWidth <= breakpoints.md) {
        mode.current = "normal";
        root.style.overflow = "auto";
        body.style.overflow = "auto";
        if (prevMode === "section" && !initial) location.reload();
      } else if (window.innerWidth > breakpoints.md) {
        mode.current = "section";
        root.style.overflow = "hidden";
        body.style.overflow = "hidden";
        if (prevMode === "normal" && !initial) location.reload();
      }
    },
    [breakpoints.md]
  );

  const windowResize = useCallback(
    (ev: Event) => {
      const window = ev.target as Window;
      setViewport({
        height: window.innerHeight,
        width: window.innerWidth,
      });
      transitionOff();
      translate(window.innerWidth, window.innerHeight);
      setMode();
    },
    [setMode, translate]
  );

  useEffect(() => {
    const container = scrollContainerRef.current;
    if (scrollContainerRef.current && direction === "vertical") {
      scrollContainerRef.current.addEventListener("wheel", wheel);
      scrollContainerRef.current.addEventListener("wheel", scroll);
    }

    setMode(true);

    return () => {
      if (container && direction === "vertical") {
        container.removeEventListener("wheel", wheel);
        container.removeEventListener("wheel", scroll);
      }
    };
  }, [scroll, wheel, direction, breakpoints.md, setMode]);

  useEffect(() => {
    window.addEventListener("resize", windowResize);
    return () => window.removeEventListener("resize", windowResize);
  }, [windowResize]);

  useEffect(() => {
    if (scrollContainerRef.current) {
      const root = document.getElementById("root");
      if (root) root.scrollTo(0, 0);
      scrollContainerRef.current.scrollTo(0, 0);
      scrollContainerRef.current.style.transform = "translate(0px, 0px)";
    }
  }, [direction]);

  return (
    <ScrollContainer
      id={id}
      ref={scrollContainerRef}
      transitionTime={TRANSITION_TIME}
      width={direction === "vertical" ? viewport.width : pnls * viewport.width}
      direction={direction}
      height={
        direction === "vertical" ? pnls * viewport.height : viewport.height
      }
      className="scroll-section"
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            width: viewport.width,
            height: viewport.height,
            fullSize: fullSize,
          });
        }
        return child;
      })}
    </ScrollContainer>
  );
});

export default ScrollSection;
