import { useState, useRef, useCallback, useEffect, useMemo } from "react";
import Hammer from "hammerjs";
import lottie from "lottie-web";
import { CarrouselProps, OverlayType } from "./types";
import { Container, Image, OverlayImage, logoStyles, Button } from "./styles";
import { ScrollSection, Section } from "../../../../components";
import Logo from "../../../../components/Logo/Logo";
import { TRANSITION_TIME } from "../../../../utils/constants";
import { delayMs } from "../../../../utils/utils";
import ExploreLottie from "../../../../assets/explore.json";

const IMAGE_TIMEOUT = 3000;

const Carrousel = ({ isVisible, isMobile, slides, startAnimation, onExplore }: CarrouselProps) => {
  const intervalRef = useRef<NodeJS.Timer | null>(null);
  const animationEndRef = useRef<boolean>(true);
  const scrollSectionRef = useRef<any>();
  const carrouselRef = useRef<HTMLDivElement>(null);
  const hammerRef = useRef<any>(null);
  const activeRef = useRef<number>(1);
  const slidesCountRef = useRef<number>(0);
  const exploreRef = useRef<HTMLButtonElement | null>(null);
  const [active, setActive] = useState(1);
  const [overlay, showOverlay] = useState<OverlayType>("none");

  const handleExplore = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    onExplore();
  };

  const localSlides = useMemo(() => {
    if (slides.length === 0) return [];

    return [slides[slides.length - 1], ...slides, slides[0]];
  }, [slides]);

  const goNext = useCallback(() => {
    if (!animationEndRef.current) return;

    if (activeRef.current < slidesCountRef.current + 1) {
      setActive(activeRef.current + 1);
      scrollSectionRef.current.scroll(activeRef.current + 1);
      if (activeRef.current === slidesCountRef.current) {
        setTimeout(async () => {
          showOverlay("first");
          setActive(1);
          activeRef.current = 1;
          scrollSectionRef.current.scroll(1, false);
          await delayMs(100);
          showOverlay("none");
        }, TRANSITION_TIME);
      }
      activeRef.current += 1;
      animationEndRef.current = false;
    }
  }, []);

  const goPrev = useCallback(() => {
    if (!animationEndRef.current) return;

    if (activeRef.current > 0) {
      setActive(activeRef.current - 1);
      scrollSectionRef.current.scroll(activeRef.current - 1);
      if (activeRef.current === 1) {
        setTimeout(async () => {
          showOverlay("last");
          setActive(slidesCountRef.current);
          activeRef.current = slidesCountRef.current;
          scrollSectionRef.current.scroll(slidesCountRef.current, false);
          await delayMs(100);
          showOverlay("none");
        }, TRANSITION_TIME);
      }
      activeRef.current -= 1;
      animationEndRef.current = false;
    }
  }, []);

  const changeSlide = useCallback(
    async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      if (!animationEndRef.current) return;

      if (isMobile && localSlides[active] && localSlides[active].redirigir_a) {
        window.open(localSlides[active].redirigir_a, "_self");
        return;
      }

      const direction =
        e.clientX > window.innerWidth * 0.75 ? "right" : e.clientX < window.innerWidth * 0.25 ? "left" : undefined;

      if (direction === "right") {
        goNext();
        animationEndRef.current = false;
      } else if (direction === "left") {
        goPrev();
        animationEndRef.current = false;
      } else if (localSlides[active] && localSlides[active].redirigir_a) {
        window.open(localSlides[active].redirigir_a, "_self");
      }
    },
    [goNext, goPrev, localSlides, active, isMobile]
  );

  const automaticAnimation = useCallback(() => {
    intervalRef.current = setInterval(() => goNext(), IMAGE_TIMEOUT);
  }, [goNext]);

  const onAnimationEnd = useCallback(() => {
    animationEndRef.current = true;
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      automaticAnimation();
    }
  }, [automaticAnimation]);

  useEffect(() => {
    if (startAnimation) {
      automaticAnimation();
    } else if (intervalRef.current) {
      clearInterval(intervalRef.current);
    }

    return () => {
      if (intervalRef.current) clearInterval(intervalRef.current);
    };
  }, [automaticAnimation, slides.length, startAnimation]);

  const unsubscribe = () => {
    if (hammerRef?.current) {
      hammerRef.current.remove("swipeleft", goNext);
      hammerRef.current.remove("swiperight", goPrev);
    }
  };

  const subscribe = () => {
    if (carrouselRef?.current) {
      hammerRef.current = new Hammer(carrouselRef.current);
      hammerRef.current.on("swipeleft", goNext);
      hammerRef.current.on("swiperight", goPrev);
    }
  };

  useEffect(() => {
    slidesCountRef.current = slides?.length || 0;
  }, [slides]);

  useEffect(() => {
    scrollSectionRef.current.scroll(1);
  }, []);

  useEffect(() => {
    subscribe();

    return () => unsubscribe();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [carrouselRef]);

  useEffect(() => {
    lottie.loadAnimation({
      name: "explore",
      container: exploreRef.current!,
      renderer: "svg",
      loop: true,
      autoplay: true,
      animationData: ExploreLottie,
    });

    return () => {
      lottie.destroy("explore");
    };
  }, [isVisible]);

  return (
    <Container id="carrousel-section" onClick={changeSlide} startAnimation={startAnimation} ref={carrouselRef}>
      {!isMobile && isVisible && <Logo styles={logoStyles} />}
      <ScrollSection
        id="home-carrousel"
        ref={scrollSectionRef}
        direction="horizontal"
        onAnimationEnd={onAnimationEnd}
        fullSize
      >
        {localSlides.map((image, index) => (
          <Section key={index}>
            <Image src={image.imagen} alt="carrousel-image" loading="lazy" />
          </Section>
        ))}
      </ScrollSection>
      {slides?.length > 0 ? (
        <>
          <OverlayImage visible={overlay === "first"} src={slides[0]?.imagen} alt="carrousel-image" loading="lazy" />
          <OverlayImage
            visible={overlay === "last"}
            src={slides[slides.length - 1]?.imagen}
            alt="carrousel-image"
            loading="lazy"
          />
        </>
      ) : null}
      <Button visible={isVisible} ref={exploreRef} onClick={handleExplore} />
    </Container>
  );
};

export default Carrousel;
