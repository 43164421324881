import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT, VIDEO_ASPECT_RELATION } from "../../utils/constants";

export const Container = styled.div`
  background-color: white;
  margin-top: ${NAVBAR_HEIGHT}px;
  height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const TopHeader = styled.div`
  position: relative;
  width: 100%;
  padding: 0 64px;
  height: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  text-transform: uppercase;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      height: 230px;
      padding: 0 16px;
    }
  `}
`;

export const TopHeaderImage = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 0;

  transform: translateY(-100px);
  opacity: 0;
  transition: all 1s ease;
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;

  &.active {
    transform: translateY(0);
    opacity: 1;
  }
`;

export const Client = styled.h4`
  margin: 0;
  font-size: 36px;
  z-index: 1;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 28px;
    }
  `}
`;

export const WorkName = styled.h1`
  margin: 0;
  font-size: 128px;
  line-height: 112px;
  z-index: 1;
  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 56px;
      line-height: 48px;
    }
  `}
`;

export const WorkType = styled.h1`
  margin: 0;
  margin-top: 24px;
  font-size: 24px;
  z-index: 1;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      font-size: 20px;
      margin-top: 24px;
    }
  `}
`;

export const WorkContent = styled.div`
  ${({ theme: { spacing, breakpoints } }) => css`
    padding: 0px ${spacing.xl}px;

    @media (max-width: ${breakpoints.lg}px) {
      padding: 0px ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      padding: 0px ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      padding: 0px ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      padding: 0px ${spacing.xs}px;
    }
  `}
`;

export const FrontImage = styled.img`
  width: 100%;
`;

export const VideoContainer = styled.div<{ width: number }>`
  width: 100%;
  margin-top: 50px;

  & > p {
    display: none;
  }

  ${({ theme, width }) => css`
    & > iframe {
      width: ${width - 2 * theme.spacing.xl}px;
      height: ${(width - 2 * theme.spacing.xl) / VIDEO_ASPECT_RELATION}px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 0 ${theme.spacing.lg}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.lg}px;
        height: ${(width - 2 * theme.spacing.lg) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 ${theme.spacing.md}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.md}px;
        height: ${(width - 2 * theme.spacing.md) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      padding: 0 ${theme.spacing.sm}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.sm}px;
        height: ${(width - 2 * theme.spacing.sm) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      padding: 0px;
      & > iframe {
        margin-left: 0px;
        width: ${width}px;
        height: ${width / VIDEO_ASPECT_RELATION}px;
      }
    }
  `}
`;
