import styled, { css, keyframes } from "styled-components";
import { NAVBAR_HEIGHT } from "../../utils/constants";

const FadeInDownAnimation = keyframes`
  0% { 
    opacity: 0; 
    transform: translateY(-100%);
  }
  100% { 
    opacity: 1; 
    transform: translateY(0);
  }
`;

export const NavbarContainer = styled.div<{
  fixed?: boolean;
  animated?: boolean;
}>`
  width: 100%;
  height: ${NAVBAR_HEIGHT}px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: ${({ theme }) => theme.palette.text};
  position: ${({ fixed }) => (fixed ? "fixed" : "static")};
  top: 0;
  left: 0;
  padding: 0 48px;
  z-index: 99;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      padding: 0 24px;
    }
  `}

  ${({ animated }) =>
    animated &&
    css`
      opacity: 0;
      animation: ${FadeInDownAnimation} 250ms ease-out 350ms;
      animation-fill-mode: forwards;
    `}
`;

export const MenuContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 56px;
  margin-right: 32px;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  `}
`;

export const MenuOption = styled.a<{ active?: boolean }>`
  font-size: 18px;
  font-weight: 900;
  text-decoration: none;
  cursor: pointer;
  color: ${({ theme, active }) => (active ? theme.palette.primary : "#fff")};

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
    transition: color 0.3s ease-out;
    -webkit-transition: color 0.3s ease-out;
    -moz-transition: color 0.3s ease-out;
    -o-transition: color 0.3s ease-out;
  }
`;

export const MenuIconContainer = styled.div`
  display: none;
  justify-content: center;
  align-items: center;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.sm}px) {
      display: flex;
    }
  `}
`;


export const Bar = styled.div`
  width: 4px;
  height: 24px;
  display: inline-block;
  background-color: white;
  transform: skewX(-10deg);
  margin-left: 5px;
`;