import styled, { css } from "styled-components";
import { motion } from "framer-motion";
import { NAVBAR_HEIGHT } from "../../utils/constants";

const FILTER_HEIGHT = 60;

export const PageContainer = styled.div<{ show: boolean }>`
  visibility: ${({ show }) => (show ? "visible" : "hidden")};
`;

export const FilterContainer = styled.div`
  top: ${NAVBAR_HEIGHT}px;
  height: ${FILTER_HEIGHT}px;
  min-height: ${FILTER_HEIGHT}px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 80px;
  position: fixed;
  background-color: #221e2b;

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      padding: 0 32px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      display: none;
    }
  `}
`;

export const CategoriesContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 0px;
  width: 100%;

  /* ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      gap: 8px;
    }
    @media (max-width: ${breakpoints.sm}px) {
      gap: 6px;
    }
  `} */
`;

export const CategoryPill = styled.h4<{ active: boolean }>`
  padding: 8px 20px 6px 20px;
  margin: 0 8px;
  font-size: 12px;
  line-height: 12px;
  border: 2px solid;
  letter-spacing: 1px;
  border-radius: 32px;
  color: #595464;
  background-color: transparent;
  text-transform: uppercase;
  cursor: pointer;
  transition: all 0.25s ease-out;
  -webkit-transition: all 0.25s ease-out;
  -moz-transition: all 0.25s ease-out;
  -o-transition: all 0.25s ease-out;

  ${({ active, theme }) => {
    if (active) {
      return css`
        color: #fff;
        border-color: ${theme.palette.primary};
        background-color: ${theme.palette.primary};
      `;
    }

    return css`
      border-color: #595464;

      &:hover {
        color: #fff;
        border-color: #fff;
      }
    `;
  }}

  ${({ theme: { breakpoints } }) => css`
    @media (max-width: ${breakpoints.md}px) {
      padding: 7px 16px 5px 16px;
      font-size: 11px;
      border-width: 2px;
      margin: 0 4px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      border-width: 2px;
      padding: 6px 12px 4px 12px;
      font-size: 10px;
    }
  `}
`;

export const WorksContainer = styled(motion.div)<{ loading: boolean }>`
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  width: 100%;
  display: grid;
  flex: 1;
  background-color: #0f071c;
  overflow-y: auto;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: minmax(28vh, 32vh);
  //grid-auto-rows: minmax(300px, 340px);
  transition: opacity 0.3s ease-out;
  -webkit-transition: opacity 0.3s ease-out;
  -moz-transition: opacity 0.3s ease-out;
  -o-transition: opacity 0.3s ease-out;
  -ms-overflow-style: none; // IE and Edge
  scrollbar-width: none; // Firefox

  ${({ loading }) => {
    if (loading) {
      return css`
        margin-top: 0;
        height: 100%;
        opacity: 0;
      `;
    }

    return css`
      opacity: 1;
      margin-top: ${NAVBAR_HEIGHT + FILTER_HEIGHT}px;
      height: ${`calc(100vh - ${NAVBAR_HEIGHT + FILTER_HEIGHT}px)`};
    `;
  }}

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(3, 1fr);
    grid-auto-rows: minmax(260px, 280px);

    ${({ loading }) => {
    if (loading) {
      return css`
        margin-top: 0;
        height: 100%;
        opacity: 0;
      `;
    }

    return css`
      opacity: 1;
      margin-top: ${NAVBAR_HEIGHT}px;
      height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
    `;
  }}
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;
