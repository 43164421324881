import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import { CaseWorkType } from "../../interfaces/work";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { getCaseWork } from "../../services/api";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs, onImagesLoaded } from "../../utils/utils";

export const useCaseWork = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const prevId = useRef<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [work, setWork] = useState<CaseWorkType | null>(null);
  const { reveal, clear } = useScrollAnimation(containerRef.current, "case-work");
  const [width, setWidth] = useState(window.innerWidth);

  const getWidth = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth)
  }, [])

  const onOptionClick = async (option: MenuOptionEnum) => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    if (option === MenuOptionEnum.HomeWorks) navigate(`/work`);
    else navigate(`/${option || ""}`);
  };

  const getWork = useCallback(async () => {
    if (!id) return;
    try {
      const [{ data }] = await Promise.all([
        getCaseWork(id),
        delayMs(INITIAL_LOADING_TIME),
      ]);
      setWork({
        ...data,
        año: `${data.año} ${data?.año_hasta ? ` - ${data?.año_hasta}` : ''}`
      });
    } catch (err) {
      console.log("Case work err: ", err);
    }
  }, [setWork, id]);

  const initialRender = useCallback(async () => {
    try {
      await getWork();
      await delayMs(250);

      if (containerRef.current) containerRef.current.scrollTo(0, 0);

      const header = document.querySelector("#image-header") as Element;
      if (header) await onImagesLoaded(header);
      await delayMs(250);
      header.classList.add("active")
    } catch (err) {
      console.log("Video work error: ", err);
    }
  }, [getWork]);

  const initialFetch = useCallback(async () => {
    await initialRender();
    dispatch(actions.setLoading(false));
    reveal();
  }, [initialRender, dispatch, reveal]);

  useEffect(() => {
    if (id && id !== prevId.current) {
      clear();
      initialFetch();
      prevId.current = id;
    }
  }, [clear, id, initialFetch]);

  return {
    width,
    loading,
    containerRef,
    work,
    onOptionClick,
  };
};
