import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT, VIDEO_ASPECT_RELATION } from "../../utils/constants";

export const Container = styled.div`
  background-color: white;
  margin-top: ${NAVBAR_HEIGHT}px;
  height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  overflow-y: auto;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  &::-webkit-scrollbar {
    display: none;
    -webkit-appearance: none;
    width: 0;
    height: 0;
  }
`;

export const VideoContainer = styled.div<{ width: number }>`
  width: 100%;

  & > p {
    display: none;
  }

  ${({ theme, width }) => css`
    & > iframe {
      width: ${width - 2 * theme.spacing.xl}px;
      height: ${(width - 2 * theme.spacing.xl) / VIDEO_ASPECT_RELATION}px;
    }

    @media (max-width: ${theme.breakpoints.lg}px) {
      padding: 0 ${theme.spacing.lg}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.lg}px;
        height: ${(width - 2 * theme.spacing.lg) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      padding: 0 ${theme.spacing.md}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.md}px;
        height: ${(width - 2 * theme.spacing.md) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      padding: 0 ${theme.spacing.sm}px;
      & > iframe {
        width: ${width - 2 * theme.spacing.sm}px;
        height: ${(width - 2 * theme.spacing.sm) / VIDEO_ASPECT_RELATION}px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      padding: 0px;
      & > iframe {
        margin-left: 0px;
        width: ${width}px;
        height: ${width / VIDEO_ASPECT_RELATION}px;
      }
    }
  `}
`;
