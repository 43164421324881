import React, {
  useState,
  useRef,
  useLayoutEffect,
  useEffect,
  useCallback,
} from "react";
import Slide from "./Slide/Slide";
import { getElementDimensions, getPositionX } from "../../utils/utils";
import { DragSliderProps } from "./types";
import { ArrowsContainer, SliderStyles, SliderWrapper } from "./styles";
import { ReactComponent as ChevronRight } from "../../assets/images/chevron_right.svg";
import { ReactComponent as ChevronLeft } from "../../assets/images/chevron_left.svg";

const DragSlider = ({
  id = "drag-slider",
  images,
  onSlideComplete,
  onSlideStart,
  activeIndex = -1,
  threshHold = 100,
  transition = 0.3,
}: DragSliderProps) => {
  const [showCursor, setShowCursor] = useState(false);
  const [mouseDown, setMouseDown] = useState(false);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const resized = useRef(false);
  const dragging = useRef(false);
  const startPos = useRef(0);
  const currentTranslate = useRef(0);
  const leftPosition = useRef(0);
  const prevTranslate = useRef(0);
  const currentIndex = useRef(activeIndex || 0);
  const currentWidth = useRef(0);
  const prevIndex = useRef(activeIndex || 0);
  const onTransitionEndRef = useRef<boolean>(true);
  const sliderRef = useRef<HTMLDivElement | null>(null);
  const cursorRef = useRef<HTMLDivElement | null>(null);
  const animationRef = useRef<number | null>(null);
  const itemsRef = useRef<(HTMLImageElement | null)[]>([]);

  useEffect(() => {
    itemsRef.current = itemsRef.current.slice(0, images?.length);
  }, [images]);

  const setPositionByIndex = useCallback(
    (w = dimensions.width) => {
      if(!onTransitionEndRef.current) return;
      if (currentIndex.current === prevIndex.current) {
        currentTranslate.current = prevTranslate.current;
        setSliderPosition();
        return;
      }

      onTransitionEndRef.current = false;
      
      const slides = document.querySelectorAll(`#${id}.slide-outer`);
      const currentSlide = slides[currentIndex.current];
      const prevSlide = slides[prevIndex.current];

      if (currentSlide && prevSlide) {
        if (currentIndex.current > 0) {
          const dir = prevIndex.current > currentIndex.current ? +1 : -1;
          const x = (w - currentSlide.clientWidth + 20) / 2;
          const delta = leftPosition.current - x;
          currentWidth.current =
          dir < 0 ? currentSlide.clientWidth : prevSlide.clientWidth;
          currentTranslate.current = prevTranslate.current + dir * delta;
          
        } else {
          currentTranslate.current = 0;
        }
        prevTranslate.current = currentTranslate.current;
        prevIndex.current = currentIndex.current;
        setSliderPosition();
      }

      setTimeout(() => onTransitionEndRef.current = true, 300)
    },
    [dimensions.width, id]
  );

  const transitionOn = useCallback(() => {
    if (sliderRef.current) {
      sliderRef.current.style.transition = `transform ${transition}s cubic-bezier(.79,.19,.18,.83)`;
    }
  }, [transition]);

  const transitionOff = () => {
    if (sliderRef.current) {
      sliderRef.current.style.transition = `none`;
    }
  };

  // watch for a change in activeIndex prop
  useEffect(() => {
    if (activeIndex !== currentIndex.current) {
      transitionOn();
      currentIndex.current = activeIndex;
      setPositionByIndex();
    }
  }, [activeIndex, setPositionByIndex, transitionOn]);

  // set width after first render
  // set position by startIndex
  // no animation on startIndex
  useLayoutEffect(() => {
    setDimensions(getElementDimensions(sliderRef));

    setPositionByIndex(getElementDimensions(sliderRef).width);
  }, [setPositionByIndex]);

  // add event listeners
  useEffect(() => {
    // set width if window resizes
    const handleResize = () => {
      transitionOff();
      const { width, height } = getElementDimensions(sliderRef);
      setDimensions({ width, height });
      setPositionByIndex(width);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, [setPositionByIndex, onSlideComplete, onSlideStart, id]);

  useEffect(() => {
    if (activeIndex === 0) {
      setTimeout(() => {
        transitionOff();
        currentIndex.current = 0;
        currentTranslate.current = 0;
        prevIndex.current = 0;
        prevTranslate.current = 0;
        setPositionByIndex();
      }, 500);
    }
  }, [activeIndex, setPositionByIndex]);

  useEffect(() => {
    const slides = document.querySelectorAll(`#${id}.slide-outer`);
    const currentSlide = slides[1];
    if (currentSlide) {
      const rect = currentSlide.getBoundingClientRect();
      leftPosition.current = rect?.left || 0;
    }
  }, [id]);

  function touchStart(index: number) {
    return function (event: any) {
      if(!onTransitionEndRef.current) return;
      transitionOn();
      setMouseDown(true);
      startPos.current = getPositionX(event);
      dragging.current = true;
      animationRef.current = requestAnimationFrame(animation);

      const slides = document.querySelectorAll(`#${id}.slide-outer`);
      const currentSlide = slides[currentIndex.current + 1];
      if (currentSlide) {
        const rect = currentSlide.getBoundingClientRect();
        leftPosition.current = rect?.left || 0;
      }

      //if (sliderRef.current) sliderRef.current.style.cursor = "grabbing";
      // if onSlideStart prop - call it
      if (onSlideStart) onSlideStart(currentIndex.current);
    };
  }

  function touchMove(event: any) {
    if (cursorRef.current) {
      const left = event.pageX;
      const top = event.pageY;
      cursorRef.current.style.left = left + "px";
      cursorRef.current.style.top = top + "px";
    }

    if (dragging.current) {
      const currentPosition = getPositionX(event);
      currentTranslate.current =
        prevTranslate.current + currentPosition - startPos.current;
    }
  }

  function touchEnd() {
    transitionOn();
    if (animationRef.current) cancelAnimationFrame(animationRef.current);
    dragging.current = false;
    setMouseDown(false);
    const movedBy = currentTranslate.current - prevTranslate.current;

    if(Math.abs(movedBy) > threshHold && onTransitionEndRef.current) {
      // if moved enough negative then snap to next slide if there is one
      if (movedBy < -threshHold && currentIndex.current < images?.length - 1)
      currentIndex.current += 1;

      // if moved enough positive then snap to previous slide if there is one
      if (movedBy > threshHold && currentIndex.current > 0)
      currentIndex.current -= 1;
    }
    
    transitionOn();
    setPositionByIndex();
    //if (sliderRef.current) sliderRef.current.style.cursor = "grab";
    // if onSlideComplete prop - call it
    if (onSlideComplete) onSlideComplete(currentIndex.current);
  }

  function moveTo(direction: number) {
    if(!onTransitionEndRef.current) return;
    if(currentIndex.current >= images?.length - 1 && direction > 0) return;
    if(currentIndex.current <= 0 && direction < 0) return;

    transitionOn();
    if (animationRef.current) cancelAnimationFrame(animationRef.current);
    dragging.current = false;
    setMouseDown(false);

    const slides = document.querySelectorAll(`#${id}.slide-outer`);
    const currentSlide = slides[currentIndex.current + 1];
    if (currentSlide) {
      const rect = currentSlide.getBoundingClientRect();
      leftPosition.current = rect?.left || 0;
    }

    currentIndex.current += direction;
    transitionOn();
    
    setPositionByIndex();
    //if (sliderRef.current) sliderRef.current.style.cursor = "grab";
    // if onSlideComplete prop - call it
    if (onSlideComplete) onSlideComplete(currentIndex.current);
  }

  function animation() {
    setSliderPosition();
    if (dragging.current) requestAnimationFrame(animation);
  }

  function setSliderPosition() {
    if (sliderRef.current){
      const frame = requestAnimationFrame(animation);
    
      sliderRef.current.style.webkitTransform = 'translateZ(0)';
      sliderRef.current.style.transform = `translateX(${currentTranslate.current}px)`;

      setTimeout(() => {
        if (frame) cancelAnimationFrame(frame);
      }, transition);
    }
  }

  const renderChildrens = useCallback(() => {
    return images?.map((image, index) => (
      <img
        key={index}
        ref={(el) => (itemsRef.current[index] = el)}
        src={image.imagen}
        alt={image.descripcion}
        loading="eager"
      />
    ));
  }, [images]);

  useLayoutEffect(() => {
    if(resized.current) return;
    const minHeight = itemsRef?.current?.reduce((acc, curr) => {
      if(curr) {
        const style = window.getComputedStyle(curr);
        const height = Number(style.height.split('px')[0]);
        if(!isNaN(height) && height < acc) return height;
        return acc;
      }
      return acc;
    }, window.visualViewport?.height || 2000)

    if(minHeight > 0) {
      resized.current = true;
      itemsRef.current?.forEach((img) => {
        if(img) img.style.height = `${minHeight}px`;
      })
    }

  }, [images])

  return (
    <SliderWrapper className="SliderWrapper">
      {/* {showCursor && (
        <Cursor ref={cursorRef} mouseDown={mouseDown}>
          <ChevronLeft />
          <ChevronRight />
        </Cursor>
      )} */}
      <SliderStyles ref={sliderRef} className="SliderStyles" transition={transition}>
        {renderChildrens()?.map((child, index) => {
          return (
            <div
              id={id}
              key={child.key}
              onTouchStart={touchStart(index)}
              onMouseDown={touchStart(index)}
              onTouchMove={touchMove}
              onMouseMove={touchMove}
              onTouchEnd={touchEnd}
              onMouseUp={touchEnd}
              onMouseEnter={(e) => {
                e.stopPropagation();
                setShowCursor(true);
              }}
              onMouseLeave={() => {
                setShowCursor(false);
                if (dragging.current) touchEnd();
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                e.stopPropagation();
              }}
              className="slide-outer"
            >
              <Slide
                child={child}
                sliderWidth={dimensions.width}
                sliderHeight={dimensions.height}
              />
            </div>
          );
        })}
      </SliderStyles>
      {images?.length > 0 && (
        <ArrowsContainer
          width={dimensions.width}
          isFirstSlide={currentIndex.current === 0}
          isLastSlide={currentIndex.current === images?.length - 1}
        >
          <ChevronLeft onClick={() => moveTo(-1)} />
          <ChevronRight
            onClick={() => moveTo(1)}
          />
        </ArrowsContainer>
      )}
    </SliderWrapper>
  );
};

export default DragSlider;
