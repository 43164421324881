import { useState, useEffect, useRef } from "react";
import lottie from "lottie-web";
import { LoadingContainer, Loader, LoaderAnim, LOADING_FADE_OUT_TIME } from "./styles";
import { LoadingProps } from "./types";
import LoaderLottie from "../../assets/loader.json";

const Loading = ({ show }: LoadingProps) => {
  const [hide, setHide] = useState(false);
  const [spinner, showSpinner] = useState(false);
  const [background, showBackground] = useState(true);
  const container = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    if (show) {
      setHide(false);
      setTimeout(() => {
        showSpinner(true);
      }, LOADING_FADE_OUT_TIME);
    } else {
      showBackground(false);
      showSpinner(false);
      setTimeout(() => {
        setHide(true);
      }, LOADING_FADE_OUT_TIME);
    }
  }, [show]);

  useEffect(() => {
    if (spinner) {
      lottie.loadAnimation({
        name: 'loader',
        container: container.current!,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: LoaderLottie,
      });
    }

    return () => {
      lottie.destroy('loader');
    };
  }, [spinner]);

  return (
    <LoadingContainer hide={!show && hide} background={background}>
      <Loader show={show} />
      {spinner && <LoaderAnim ref={container} />}
    </LoadingContainer>
  );
};

export default Loading;
