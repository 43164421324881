import { useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { actions } from "../../redux/loading";
import { INITIAL_LOADING_TIME, MENU_OPTIONS } from "../../utils/constants";
import { delayMs } from "../../utils/utils";
import Logo from "../Logo/Logo";
import Menu from "../Menu/Menu";
import {
  NavbarContainer,
  MenuContainer,
  MenuOption,
  MenuIconContainer,
  Bar,
} from "./styles";
import { NavbarProps, MenuOptionEnum } from "./types";

const Navbar = ({
  fixed,
  animated = true,
  actualOption,
  className,
  onOptionClick,
}: NavbarProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showMenu, toggleMenu] = useState(false);

  const handleOptionClicked = useCallback(
    async (option: MenuOptionEnum) => {
      if (actualOption !== option && option === MenuOptionEnum.HomeWorks) {
        dispatch(actions.setLoading(true));
        await delayMs(INITIAL_LOADING_TIME + 100);
        navigate("/work");
      } else {
        onOptionClick(option);
      }
      toggleMenu(false);
    },
    [onOptionClick, navigate, dispatch, actualOption]
  );

  const renderOptions = useCallback(() => {
    return MENU_OPTIONS.map((option) => (
      <MenuOption
        key={option.id}
        active={actualOption === option.id}
        onClick={() => handleOptionClicked(option.id)}
      >
        {option.label}
      </MenuOption>
    ));
  }, [actualOption, handleOptionClicked]);

  return (
    <>
      <NavbarContainer className={className} fixed={fixed} animated={animated}>
        <Logo onClick={() => handleOptionClicked(MenuOptionEnum.Carrousel)} />
        <MenuContainer>{renderOptions()}</MenuContainer>
        <MenuIconContainer onClick={() => toggleMenu(true)}>
          <Bar />
          <Bar />
          <Bar />
        </MenuIconContainer>
      </NavbarContainer>
      <Menu
      actualOption={actualOption}
        show={showMenu}
        onClose={() => toggleMenu(false)}
        onOptionClick={handleOptionClicked}
      />
    </>
  );
};

export default Navbar;
