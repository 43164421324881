import { configureStore } from '@reduxjs/toolkit'
import works from './works';
import loading from './loading';

export const store = configureStore({
  reducer: {
    works,
    loading
  },
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch