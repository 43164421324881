import { useCallback, useEffect } from "react";

export const useScrollAnimation = (
  container: HTMLElement | null,
  className: string = "",
  disabled: boolean = false,
) => {
  const reveal = useCallback(() => {
    if(disabled) return;

    let reveals: NodeListOf<Element>;
    if (className) {
      reveals = document.querySelectorAll(`.${className} .reveal`);
    } else {
      reveals = document.querySelectorAll(".reveal");
    }
    
    for (var i = 0; i < reveals?.length; i++) {
      var windowHeight = window.innerHeight;
      var elementTop = reveals[i].getBoundingClientRect().top;
      var elementVisible = 50;
      if (elementTop < windowHeight - elementVisible) {
        reveals[i].classList.add("active");
      }
    }
  }, [className, disabled]);

  const clear = useCallback(() => {
    let reveals: NodeListOf<Element>;
    if (className) {
      reveals = document.querySelectorAll(`.${className} .reveal`);
    } else {
      reveals = document.querySelectorAll(".reveal");
    }
    for (var i = 0; i < reveals?.length; i++) {
      reveals[i].classList.remove("active");
    }
  }, [className]);

  useEffect(() => {
    if (container) container.addEventListener("scroll", reveal);

    return () => {
      if (container) container.removeEventListener("scroll", reveal);
    };
  }, [container, reveal]);

  return {
    clear,
    reveal,
  };
};
