import styled, { css } from "styled-components";
import { NAVBAR_HEIGHT } from "../../../../utils/constants";

export const HomeWorksContainer = styled.main`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  background-color: #0f071c;
`;

export const Grid = styled.div<{ show: boolean }>`
  position: relative;
  z-index: 2;
  display: block;
  margin: 0 auto;
  width: 100%;
  display: grid;
  flex: 1;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 1fr;
  transition: all 0.2s ease-out;
  -webkit-transition: all 0.2s ease-out;
  -moz-transition: all 0.2s ease-out;
  -o-transition: all 0.2s ease-out;

  @media (min-width: ${({ theme }) => theme.breakpoints.md + 1}px) {
    ${({ show }) =>
      show
        ? css`
            transition-delay: 0.485s;
            max-height: calc(100% - ${NAVBAR_HEIGHT}px);
          `
        : css`
            max-height: 100%;
          `};
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    grid-template-columns: repeat(2, 1fr);
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    grid-template-columns: repeat(1, 1fr);
  }
`;

export const ViewAll = styled.div`
  background-color: #0f071c;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  cursor: pointer;
  transition: background-color 0.25s ease-out;
  -webkit-transition: background-color 0.25s ease-out;
  -moz-transition: background-color 0.25s ease-out;
  -o-transition: background-color 0.25s ease-out;

  &:hover {
    background-color: ${({ theme }) => theme.palette.primary};
  }
`;

export const ViewAllText = styled.div`
  font-size: 36px;
  line-height: 36px;
  font-weight: bold;
  color: white;
  // letter-spacing: 16px;
`;
