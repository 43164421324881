import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import { VideoWorkType } from "../../interfaces/work";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { getVideoWork } from "../../services/api";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs, onImagesLoaded } from "../../utils/utils";

export const useVideoWork = () => {
  const { id = "" } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { loading } = useSelector((state: RootState) => state.loading);
  const prevId = useRef<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const [picIndex, setPicIndex] = useState(0);
  const [backstageIndex, setBackstageIndex] = useState(0);
  const [work, setWork] = useState<VideoWorkType | null>(null);
  const { reveal, clear } = useScrollAnimation(containerRef.current, "video-work");
  const [width, setWidth] = useState(window.innerWidth);

  const getWidth = () => {
    setWidth(window.innerWidth);
  }

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth)
  }, [])

  const onOptionClick = async (option: MenuOptionEnum) => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    if (option === MenuOptionEnum.HomeWorks) navigate(`/work`);
    else navigate(`/${option || ""}`);
  };

  const getWork = useCallback(async () => {
    if (!id) return;
    try {
      const [{ data }] = await Promise.all([
        getVideoWork(id),
        delayMs(INITIAL_LOADING_TIME),
      ]);
      setWork(data);
    } catch (err) {
      console.log("Video work err: ", err);
    }
  }, [setWork, id]);

  const initialRender = useCallback(async () => {
    try {
      await getWork();
      await delayMs(500);

      if (containerRef.current) containerRef.current.scrollTo(0, 0);

      const backstage = document.querySelector("#backstage-slider") as Element;
      const pics = document.querySelector("#pics-slider") as Element;
      if (backstage) await onImagesLoaded(backstage);
      if (pics) await onImagesLoaded(pics);
    } catch (err) {
      console.log("Video work error: ", err);
    }
  }, [getWork]);

  const initialFetch = useCallback(async () => {
    await initialRender();
    dispatch(actions.setLoading(false));
    reveal();
  }, [initialRender, dispatch, reveal]);

  useEffect(() => {
    if (id && id !== prevId.current) {
      setBackstageIndex(0);
      setPicIndex(0);
      clear();
      initialFetch();
      prevId.current = id;
    }
  }, [clear, id, initialFetch]);

  return {
    width,
    loading,
    containerRef,
    work,
    picIndex,
    backstageIndex,
    setPicIndex,
    setBackstageIndex,
    onOptionClick,
  };
};
