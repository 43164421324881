import { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { Category, Work } from "../../interfaces/work";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { getCategories, getWorks } from "../../services/api";
import { delayMs, onImagesLoaded } from "../../utils/utils";
import { useResponsive } from "../../hooks/useResponsive";

const INITIAL_LOADING_TIME = 2000;

export const useWorks = () => {
  const { filter = "" } = useParams();
  const firstRenderRef = useRef<boolean>(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const [allWorks, setAllWorks] = useState<Work[]>([]);
  const [works, setWorks] = useState<Work[]>([]);
  const [categories, setCategories] = useState<Category[]>([]);
  const [activeCategories, setActiveCategories] = useState<Category[]>([]);
  const { isMobile } = useResponsive();

  const onOptionClick = async (option: MenuOptionEnum) => {
    if (option !== MenuOptionEnum.HomeWorks) {
      dispatch(actions.setLoading(true));
      await delayMs(INITIAL_LOADING_TIME + 100);
      navigate(`/${option || ""}`);
    }
  };

  const sortWorks = (works: Work[]) => {
    let priorityWorks = [];
    let restOfWorks = [];
    for (const work of works) {
      if (work.prioritario) priorityWorks.push(work);
      else restOfWorks.push(work);
    }

    const w = [
      ...priorityWorks.sort((a, b) => a.orden > b.orden ? 1 : -1),
      ...shuffleArray(restOfWorks)
    ];
    setWorks(w);
  }

  const shuffleArray = (array: Work[]) =>
    array
      .map((value) => ({ value, sort: Math.random() }))
      .sort((a, b) => a.sort - b.sort)
      .map(({ value }) => value);

  const onCategoryClicked = (category: Category, works?: Work[]) => {
    let newCategories = [];
    if (isActive(category)) {
      newCategories = activeCategories.filter((c) => c.id !== category.id);
    } else {
      newCategories = [...activeCategories, category];
    }

    setActiveCategories(newCategories);
    const ids = newCategories.map((c) => c.id);
    let localWorks = works ? [...works] : [...allWorks];
    if (ids.length > 0) {
      localWorks = localWorks.filter((w) => w.categoria.some((c) => ids.includes(c.id)));
    }
    sortWorks(localWorks);
  };

  const getAllWorks = async (categories: string = "") => {
    try {
      const { data } = await getWorks(categories);
      sortWorks(data || []);
      setAllWorks(data || []);
      return data;
    } catch (err) {
      console.log("Works err: ", err);
      throw err;
    }
  };

  const getAllCategories = async () => {
    try {
      const { data } = await getCategories();
      setCategories(data || []);
      return data;
    } catch (err) {
      console.log("Works err: ", err);
      throw err;
    }
  };

  const isActive = useCallback(
    (category: Category) => activeCategories.some((cat) => cat.id === category.id),
    [activeCategories]
  );

  const initialRender = useCallback(async () => {
    try {
      const [works,categories] = await Promise.all([getAllWorks(), getAllCategories()]);
      await delayMs(1000);
      if(filter) {
        const category = categories.find(c => c.filtro === filter);
        if(category) {
          onCategoryClicked(category, works);
          navigate('/work', { replace: true })
        }
      }
      const container = document.querySelector(".works-grid") as Element;
      if (container) await onImagesLoaded(container);
      await delayMs(1000);
    } catch (err) {
      console.log("Home error: ", err);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const initialFetch = useCallback(async () => {
    await Promise.all([initialRender(), delayMs(INITIAL_LOADING_TIME)]);
    dispatch(actions.setLoading(false));
  }, [initialRender, dispatch]);

  useEffect(() => {
    if (!firstRenderRef.current) {
      initialFetch();
      firstRenderRef.current = true;
    }
  }, [initialFetch]);

  return {
    isMobile,
    loading,
    works,
    categories,
    isActive,
    onOptionClick,
    onCategoryClicked,
  };
};
