import { AnimatePresence } from "framer-motion";
import { useCallback } from "react";
import { Navbar } from "../../components";
import { MenuOptionEnum } from "../../components/Navbar/types";
import WorkThumb from "../../components/WorkThumb/WorkThumb";
import { Category, Work } from "../../interfaces/work";
import { CategoriesContainer, CategoryPill, FilterContainer, WorksContainer } from "./styles";
import { useWorks } from "./useWorks";
import { Helmet } from "react-helmet";

export const Works = () => {
  const { works, categories, isActive, onOptionClick, onCategoryClicked } = useWorks();

  const renderItems = useCallback(() => {
    return (
      <AnimatePresence>
        {works.map((work: Work) => (
          <WorkThumb key={`${work.tipo}-${work.id}`} work={work} />
        ))}
      </AnimatePresence>
    );
  }, [works]);

  const renderCategories = useCallback(() => {
    return categories.map((category: Category) => (
      <CategoryPill active={isActive(category)} key={category.id} onClick={() => onCategoryClicked(category)}>
        {category.nombre}
      </CategoryPill>
    ));
  }, [categories, isActive, onCategoryClicked]);

  return (
    <>
      <Helmet>
        <title>VRØDAS</title>
        <meta
          name="description"
          content="We are a full service creative boutique specialized in custom-made visual solutions for the music market new era."
        />
      </Helmet>
      <div className="works-grid">
        <Navbar fixed actualOption={MenuOptionEnum.HomeWorks} onOptionClick={onOptionClick} />
        <FilterContainer>
          <CategoriesContainer>{renderCategories()}</CategoriesContainer>
        </FilterContainer>
        <WorksContainer layout loading={false}>
          {renderItems()}
        </WorksContainer>
      </div>
    </>
  );
};

export default Works;
