import { WorkSectionContainer, Slash, Title } from "./styles";
import { WorkSectionTitleProps } from "./types";

const WorkSectionTitle = ({ title }: WorkSectionTitleProps) => {
  return (
    <WorkSectionContainer>
      <Slash />
      <Title>{title}</Title>
    </WorkSectionContainer>
  );
};

export default WorkSectionTitle;