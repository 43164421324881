import styled from "styled-components";

export const GoBackButton = styled.button`
  outline: none;
  border: 2px solid ${({ theme }) => theme.palette.text};
  border-radius: 6px;
  color: ${({ theme }) => theme.palette.text};
  padding: 9px 8px 6px 8px;
  cursor: pointer;
  font-size: 14px;
  line-height: 14px;
  font-weight: 700;
  font-family: "VrodasFont";
  text-transform: uppercase;
  background-color: transparent;
  min-width: 115px;
  width: 115px;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
    border-color: ${({ theme }) => theme.palette.primary};
  }
`;
