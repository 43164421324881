import { useEffect, useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { useScrollAnimation } from "../../hooks/useScrollAnimation";
import { DesignWorkType } from "../../interfaces/work";
import { actions } from "../../redux/loading";
import { RootState } from "../../redux/store";
import { getDesignWork } from "../../services/api";
import { INITIAL_LOADING_TIME } from "../../utils/constants";
import { delayMs, onImagesLoaded } from "../../utils/utils";

export const useDesignWork = () => {
  const { id = "" } = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { loading } = useSelector((state: RootState) => state.loading);
  const prevId = useRef<string>("");
  const containerRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [galeryIndex, setGaleryIndex] = useState(0);
  const [work, setWork] = useState<DesignWorkType | null>(null);
  const { reveal, clear } = useScrollAnimation(containerRef.current, "design-work");
  const [width, setWidth] = useState(0);

  const getWidth = () => {
    if (contentRef.current) setWidth(contentRef.current.clientWidth);
    else setWidth(0)
  }

  useEffect(() => {
    getWidth();
    window.addEventListener('resize', getWidth);

    return () => window.removeEventListener('resize', getWidth)
  }, [])
  
  const onOptionClick = async (option: MenuOptionEnum) => {
    dispatch(actions.setLoading(true));
    await delayMs(INITIAL_LOADING_TIME + 100);
    if (option === MenuOptionEnum.HomeWorks) navigate(`/work`);
    else navigate(`/${option || ""}`);
  };

  const getWork = useCallback(async () => {
    if (!id) return;
    try {
      const [{ data }] = await Promise.all([
        getDesignWork(id),
        delayMs(INITIAL_LOADING_TIME),
      ]);
      setWork(data);
    } catch (err) {
      console.log("Design work err: ", err);
    }
  }, [setWork, id]);

  const initialRender = useCallback(async () => {
    try {
      await getWork();
      await delayMs(500);

      if (containerRef.current) containerRef.current?.scrollTo(0, 0);

      const mosaic = document.querySelector("#design-mosaic") as Element;
      const gallery = document.querySelector("#design-gallery") as Element;
      const promises = [];
      if (mosaic) promises.push(onImagesLoaded(mosaic));
      if (gallery) promises.push(onImagesLoaded(gallery));
      await Promise.all(promises)
    } catch (err) {
      console.log("Design work error: ", err);
    }
  }, [getWork]);

  const initialFetch = useCallback(async () => {
    await initialRender();
    dispatch(actions.setLoading(false));
    reveal();
  }, [initialRender, dispatch, reveal]);

  useEffect(() => {
    if (id && id !== prevId.current) {
      setGaleryIndex(0);
      clear();
      initialFetch();
      prevId.current = id;
    }
  }, [clear, id, initialFetch]);

  return {
    loading,
    width,
    containerRef,
    contentRef,
    work,
    galeryIndex,
    setGaleryIndex,
    onOptionClick,
  };
};
