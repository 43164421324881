import { IconContainer, Bar } from "./styles";
import { MenuIconProps } from "./types";

const MenuIcon = ({hide, onClick}: MenuIconProps) => {
  return (
    <IconContainer hide={hide} onClick={onClick}>
      <Bar />
      <Bar />
      <Bar />
    </IconContainer>
  )
}

export default MenuIcon;