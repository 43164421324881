import styled, { css } from "styled-components";

export const SliderStyles = styled.div<{ transition : number}>`
  all: initial;
  width: 100%;
  height: fit-content;
  max-height: 100vh;
  display: inline-flex;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);
  /* cursor: none; */

  .slide-outer {
    display: flex;
    align-items: center;
    height: fit-content;
  }

  ${({ theme: { breakpoints, spacing }, transition }) => css`
    margin-left: ${spacing.xl}px;

    will-change: transform;
    transition: transform ${transition || 300}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -webkit-transition: transform ${transition || 300}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -moz-transition: transform ${transition || 300}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -o-transition: transform ${transition || 300}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);


    @media (max-width: ${breakpoints.lg}px) {
      margin-left: ${spacing.lg}px;
    }

    @media (max-width: ${breakpoints.md}px) {
      margin-left: ${spacing.md}px;
    }

    @media (max-width: ${breakpoints.sm}px) {
      margin-left: ${spacing.sm}px;
    }

    @media (max-width: ${breakpoints.xs}px) {
      margin-left: ${spacing.xs}px;
    }
  `}
`;

export const SliderWrapper = styled.div`
  overflow: hidden;
  width: 100%;
  height: fit-content;
  max-height: 100vh;
  pointer-events: none;
`;

export const ArrowsContainer = styled.div<{
  width: number;
  isFirstSlide: boolean;
  isLastSlide: boolean;
}>`
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  gap: 8px;
  user-select: none;

  & > svg:hover path {
    fill: ${({ theme: { palette } }) => palette.primary};
  }

  & > svg {
    cursor: pointer;
    & > path {
      transition: all 0.2s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      -moz-transition: all 0.2s ease-in-out;
      -o-transition: all 0.2s ease-in-out;
      fill: ${({ theme: { palette } }) => palette.gray2D};
    }
  }

  ${({ isFirstSlide, isLastSlide, theme: { palette } }) => {
    if (isFirstSlide) {
      return css`
        & > svg:first-child {
          cursor: default;
          & > path {
            fill: ${palette.grayCC};
          }
        }

        & > svg:first-child:hover > path {
          fill: ${palette.grayCC};
        }
      `;
    }

    if (isLastSlide) {
      return css`
        & > svg:nth-child(2) {
          cursor: default;
          & > path {
            fill: ${palette.grayCC};
          }
        }

        & > svg:nth-child(2):hover > path {
          fill: ${palette.grayCC};
        }
      `;
    }
  }}
`;

export const Cursor = styled.div<{ mouseDown: boolean }>`
  position: absolute;
  z-index: 999;
  height: 32px;
  width: 32px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 300ms ease-in-out;
  -webkit-transition: transform 300ms ease-in-out;
  -moz-transition: transform 300ms ease-in-out;
  -o-transition: transform 300ms ease-in-out;

  & > svg {
    width: 24px;
    height: 24px;
    transition: opacity 250ms ease-in-out;
    -webkit-transition: opacity 250ms ease-in-out;
    -moz-transition: opacity 250ms ease-in-out;
    -o-transition: opacity 250ms ease-in-out;
    & > path {
      fill: white;
    }
  }

  & > svg:first-child {
    margin-right: -8px;
  }

  ${({ mouseDown }) =>
    mouseDown
      ? css`
          transform: translate(-50%, -50%) scale(1.4);

          & > svg {
            visibility: visible;
            opacity: 1;
          }
        `
      : css`
          transform: translate(-50%, -50%);

          & > svg {
            visibility: hidden;
            opacity: 0;
          }
        `}
`;
