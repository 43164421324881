import { Navbar, ScrollSection, Section } from "../../components";
import { MenuOptionEnum } from "../../components/Navbar/types";

import Carrousel from "./components/Carrousel/Carrousel";
import Works from "./components/Works/Works";
import WeAre from "./components/WeAre/WeAre";
import Contact from "./components/Contact/Contact";
import Menu from "../../components/Menu/Menu";
import MenuIcon from "./components/MenuIcon/MenuIcon";
import { useHome } from "./useHome";
import { Helmet } from "react-helmet";
import WeDo from "./components/WeDo/WeDo";

const Home = () => {
  const {
    isMobile,
    scrollSectionRef,
    loading,
    works,
    images,
    actualOption,
    showMenu,
    weAreBlocks,
    weDoBlocks,
    assets,
    onOptionClick,
    onSectionChanged,
    onAnimationEnd,
    onCloseMenu,
    menuClickHandle,
    handleExplore,
  } = useHome();

  return (
    <>
      <Helmet>
        <title>VRØDAS</title>
        <meta
          name="description"
          content="We are a full service creative boutique specialized in custom-made visual solutions for the music market new era."
        />
      </Helmet>
      <div className="home">
        {(actualOption !== MenuOptionEnum.Carrousel || isMobile) && (
          <Navbar fixed animated={!isMobile} actualOption={actualOption} onOptionClick={onOptionClick} />
        )}
        {!isMobile && (
          <MenuIcon hide={actualOption !== MenuOptionEnum.Carrousel || loading} onClick={menuClickHandle} />
        )}
        <Menu show={showMenu} onClose={onCloseMenu} onOptionClick={onOptionClick} actualOption={actualOption} />
        <ScrollSection ref={scrollSectionRef} onSectionChanged={onSectionChanged} onAnimationEnd={onAnimationEnd}>
          <Section>
            <Carrousel
              isVisible={actualOption === MenuOptionEnum.Carrousel}
              isMobile={isMobile}
              startAnimation={!loading}
              slides={images || []}
              onExplore={handleExplore}
            />
          </Section>
          <Section>
            <Works works={works} isVisible={actualOption === MenuOptionEnum.HomeWorks} />
          </Section>
          <Section>
            <WeDo
              backgroundImage={assets?.we_do_background}
              blocks={weDoBlocks}
              isVisible={actualOption === MenuOptionEnum.WeDo}
            />
          </Section>
          <Section>
            <WeAre
              backgroundImage={assets?.we_are_background}
              barImage={assets?.we_are_bar}
              blocks={weAreBlocks}
              isVisible={actualOption === MenuOptionEnum.WeAre}
            />
          </Section>
          <Section>
            <Contact
              backgroundImage={assets?.contact_background}
              barImage1={assets?.contact_bar_1}
              barImage2={assets?.contact_bar_2}
              isVisible={actualOption === MenuOptionEnum.Contact}
            />
          </Section>
        </ScrollSection>
      </div>
    </>
  );
};

export default Home;
