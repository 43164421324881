import { useCallback, useMemo } from "react";
import { useVideoWork } from "./useVideoWork";
import { Container, VideoContainer } from "./styles";
import { MenuOptionEnum } from "../../components/Navbar/types";
import { VideoWorkType } from "../../interfaces/work";
import { WorkHeader } from "../../components/WorkHeader/WorkHeader";
import { WorkItem } from "../../components/WorkHeader/types";
import WorkSectionTitle from "../../components/WorkSectionTitle/WorkSectionTitle";
import OtherProjects from "../../components/OtherProjects/OtherProjects";
import DragSlider from "../../components/DragSlider/DragSlider";
import { CustomNavbar } from "../../components/WorkNavbar/WorkNavbar";
import Reveal from "../../components/Reveal/Reveal";
import { useResponsive } from "../../hooks/useResponsive";
import { Helmet } from "react-helmet";
import ShareButton from "../../components/ShareButton/ShareButton";

const items: WorkItem[] = [
  { key: "artista", label: "Artist" },
  { key: "director", label: "Director" },
  { key: "compania", label: "Company" },
  { key: "dop", label: "DoP" },
  { key: "año", label: "Year" },
  { key: "productor_ejecutivo", label: "Producer" },
];

export const VideoWork = () => {
  const { width, work, onOptionClick, picIndex, backstageIndex, setPicIndex, setBackstageIndex, containerRef } =
    useVideoWork();
  const { isMobile } = useResponsive();
  
  const workItems = useMemo(() => {
    if (!work) return [];
    return items.map((item) => ({
      ...item,
      value: (work[item.key as keyof VideoWorkType] as string) || "",
    }));
  }, [work]);

  const renderPics = useCallback(() => {
    const pics = work?.galeria_pics?.filter((image) => image.imagen);
    if (!pics || pics.length === 0) return null;
    return (
      <Reveal>
        <WorkSectionTitle title="PICS" />
        <DragSlider id="pics-slider" onSlideComplete={setPicIndex} activeIndex={picIndex} images={pics} />
      </Reveal>
    );
  }, [picIndex, setPicIndex, work?.galeria_pics]);

  const renderBackstage = useCallback(() => {
    const pics = work?.galeria_backstage?.filter((image) => image.imagen);
    if (!work?.embed_code_video_backstage && (!pics || pics.length === 0)) return null;
    return (
      <Reveal>
        <WorkSectionTitle title="BACKSTAGE" />
        {work?.embed_code_video_backstage && (
          <VideoContainer
            width={width}
            style={{ marginBottom: 12 }}
            dangerouslySetInnerHTML={{
              __html: work?.embed_code_video_backstage || "",
            }}
          />
        )}
        {pics && pics?.length > 0 && (
          <DragSlider
            id="backstage-slider"
            onSlideComplete={setBackstageIndex}
            activeIndex={backstageIndex}
            images={pics}
          />
        )}
      </Reveal>
    );
  }, [backstageIndex, setBackstageIndex, work?.galeria_backstage, work?.embed_code_video_backstage, width]);

  const title = `VRØDAS | ${work?.artista || ""} - ${work?.nombre || ""}`

  return (
    <>
      <Helmet>
        <title>{title}</title>
        <meta name="description" content={work?.descripcion_en || ""} />
        <meta name="thumbnail" content={work?.thumb} />
        <meta property="og:title" content={title} />
        <meta property="og:type" content="music video" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={work?.thumb} />
        <meta property="og:description" content={work?.descripcion_en || ""} />
        <meta property="og:site_name" content="VRØDAS" />
      </Helmet>
      <div className="video-work">
        <ShareButton title={title} text={work?.descripcion_en || ""} url={window.location.href} />
        <CustomNavbar animated={false} fixed actualOption={MenuOptionEnum.HomeWorks} onOptionClick={onOptionClick} />
        <Container ref={containerRef}>
          <WorkHeader
            artist={work?.artista}
            name={work?.nombre}
            description={work?.descripcion_en || ""}
            items={workItems}
            breakColumns={false}
          />
          <Reveal delay={isMobile ? 0 : 500}>
            <VideoContainer
              width={width}
              dangerouslySetInnerHTML={{
                __html: work?.embed_code_video_principal || "",
              }}
            />
          </Reveal>
          {renderPics()}
          {renderBackstage()}
          <OtherProjects works={work?.otros_proyectos || []} />
        </Container>
      </div>
    </>
  );
};

export default VideoWork;
