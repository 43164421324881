import styled, { css } from "styled-components";
import Reveal from "../../../../components/Reveal/Reveal";
import { NAVBAR_HEIGHT } from "../../../../utils/constants";

export const ContactContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  gap: 80px;
  position: relative;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-color: #0f071c;
  padding-top: ${NAVBAR_HEIGHT}px;
  font-size: 156px;
  min-height: 100vh;

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.lg}px) {
      font-size: 132px;
    }

    @media (max-width: ${theme.breakpoints.md}px) {
      font-size: 112px;
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      font-size: 84px;
      gap: 40px;
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      font-size: 64px;
    }
  `}
`;

export const BackgroundImage = styled.img`
  background-color: #0f071c;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Figure = css`
  position: absolute;
  z-index: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const Bar1 = styled.img`
  ${Figure}
  top: -260px;
  right: -36px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xl}px) {
    right: -60px;
  }
`;

export const BarReveal = styled(Reveal)`
  ${Figure}
  bottom: 0;
  right: 210px;
  height: 115px;
`;

export const Title = styled.h1`
  margin: 0;
  font-family: "VrodasFontTitle";
  color: ${({ theme }) => theme.palette.primary};
  font-size: 1.5em;
  font-weight: 400;
  text-transform: lowercase;
  z-index: 1;
  padding: 0;
`;

export const BlackContainer = styled.div`
  background-color: #0f071c;
  padding: 12px 32px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    gap: 24px;
    width: auto;
    flex-direction: column;
    max-width: 90%;
    margin: 0 auto;
  }
`;

export const Link = styled.a`
  margin: 0;
  color: #fff;
  font-size: 0.22em;
  font-weight: 900;
  transition: color 0.25s ease-out;
  -webkit-transition: color 0.25s ease-out;
  -moz-transition: color 0.25s ease-out;
  -o-transition: color 0.25s ease-out;
  cursor: pointer;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.palette.primary};
  }
`;

export const Square = styled.div`
  width: 16px;
  height: 16px;
  margin-left: 12px;
  background-color: ${({ theme }) => theme.palette.primary};
  transform: rotate(45deg);

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    display: none;
  }
`;

export const IconsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 1.1em;
`;

export const EmptyLink = styled.a`
  text-decoration: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

export const Account = styled.p`
  color: #0f071c;
  font-size: 0.16em;
  font-weight: 900;
  background-color: #fafafa;
  padding: 4px 8px;
  z-index: 1;
  letter-spacing: 0.5px;

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 0.25em;
  }
`;

export const CircleContainer = styled.div`
  width: 110px;
  height: 110px;
  border-radius: 100%;
  background-color: ${({ theme }) => theme.palette.primary};
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.25s ease-out;
  -webkit-transition: background-color 0.25s ease-out;
  -moz-transition: background-color 0.25s ease-out;
  -o-transition: background-color 0.25s ease-out;
  cursor: pointer;
  z-index: 1;

  & > svg {
    width: 48px;
    height: 48px;
  }

  & > svg,
  & > svg > path {
    fill: white;
    transition: fill 0.25s ease-out;
    -webkit-transition: fill 0.25s ease-out;
    -moz-transition: fill 0.25s ease-out;
    -o-transition: fill 0.25s ease-out;
  }

  &:hover {
    background-color: #0f071c;

    & > svg,
    & > svg > path {
      fill: ${({ theme }) => theme.palette.primary};
    }
  }

  ${({ theme }) => css`
    @media (max-width: ${theme.breakpoints.md}px) {
      width: 90px;
      height: 90px;
      & > svg {
        width: 40px;
        height: 40px;
      }
    }

    @media (max-width: ${theme.breakpoints.sm}px) {
      width: 70px;
      height: 70px;
      & > svg {
        width: 32px;
        height: 32px;
      }
    }

    @media (max-width: ${theme.breakpoints.xs}px) {
      width: 56px;
      height: 56px;
      & > svg {
        width: 28px;
        height: 28px;
      }
    }
  `}
`;

export const BarBottom = styled.img`
  transform: translateY(-50px);
`;
