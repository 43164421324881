export enum MenuOptionEnum {
  Carrousel = "",
  HomeWorks = "home-works",
  WeDo = "we-do",
  WeAre = "we-are",
  Contact = "contact"
}

export type NavbarProps = {
  className?: string;
  fixed?: boolean;
  animated?: boolean;
  actualOption?: MenuOptionEnum;
  onOptionClick: (option: MenuOptionEnum) => void;
}