import { useState, useEffect, useContext, useCallback } from "react";
import { ThemeContext } from "styled-components";

export const useResponsive = () => {
  const { innerWidth } = window;
  const { breakpoints } = useContext(ThemeContext);
  const [isMobile, setIsMobile] = useState(false);
  const [width, setWidth] = useState(innerWidth);

  const reportWindowSize = useCallback((e: any) => {
    const actualWidth = e.target.innerWidth;
    if (actualWidth < breakpoints.md && !isMobile) setIsMobile(true);
    if (actualWidth >= breakpoints.md) setIsMobile(false);
  }, [breakpoints.md, isMobile]);

  useEffect(() => {
    setWidth(innerWidth);
    if (innerWidth < breakpoints.md) setIsMobile(true);
    else setIsMobile(false);

    window.addEventListener("resize", reportWindowSize);
    return () => window.removeEventListener("resize", reportWindowSize);
  }, [breakpoints.md, innerWidth, reportWindowSize]);

  return {
    width,
    isMobile,
  };
};