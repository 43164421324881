import styled, { css } from "styled-components";

export const ScrollContainer = styled.div<{
  transitionTime: number;
  width: number;
  height: number;
  direction?: "vertical" | "horizontal";
}>`
  position: relative;
  overflow: hidden;
  font-size: 0px;
  letter-spacing: 0px;
  word-spacing: 0px;
  transform: translateZ(0);
  -webkit-transform: translateZ(0);

  ${({ direction }) =>
    direction === "horizontal" &&
    css`
      max-height: 100vh; /* old browsers */
      max-height: 100dvh; /* new browsers */
    `}

  ${({ width, height, transitionTime, theme }) => css`
    width: ${width}px;
    height: ${height}px;
    will-change: transform;
    transition: transform ${transitionTime || 1000}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -webkit-transition: transform ${transitionTime || 1000}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -moz-transition: transform ${transitionTime || 1000}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);
    -o-transition: transform ${transitionTime || 1000}ms cubic-bezier(0.79, 0.19, 0.18, 0.83);

    @media (max-width: ${theme.breakpoints.md}px) {
      height: fit-content;
    }
  `}
`;

export const Section = styled.div<{ width?: number; height?: number; fullSize?: boolean }>`
  position: relative;
  overflow: hidden;
  display: inline-block;
  box-sizing: border-box;
  cursor: default;
  margin: 0;
  padding: 0;

  ${({ width, height, theme, fullSize }) => css`
    width: ${width}px;
    height: ${height}px;

    @media (max-width: ${theme.breakpoints.md}px) {
      min-height: fit-content;
      height: ${fullSize ? "100vh" : "fit-content"};
    }
  `}
`;
