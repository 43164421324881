import { Fragment, useEffect, useRef } from "react";
import { WeAreContainer, Block, We, Are, Paragraph, Background, Bar, BarReveal, Title } from "./styles";
import { WeAreProps } from "./types";
import Reveal from "../../../../components/Reveal/Reveal";
import { useResponsive } from "../../../../hooks/useResponsive";

const WeAre = ({ isVisible, blocks, backgroundImage, barImage }: WeAreProps) => {
  const firstRender = useRef<boolean>(true);
  const { isMobile } = useResponsive();

  const reveal = () => {
    var reveals = document.querySelectorAll(".we-are-container .reveal");
    for (var i = 0; i < reveals.length; i++) {
      reveals[i].classList.add("active");
    }
  };

  useEffect(() => {
    if (isVisible && firstRender.current && blocks?.length > 0) {
      reveal();
      firstRender.current = false;
    }
  }, [isVisible, blocks]);

  return (
    <WeAreContainer className="we-are-container" id="we-are-section">
      <Block>
       {backgroundImage && <Background src={backgroundImage} alt="we-are-background" crossOrigin="anonymous" />}
        <Reveal delay={isMobile ? 0 : 250}>
          <We className="title">we</We>{" "}
        </Reveal>
        <Reveal delay={isMobile ? 0 : 250}>
          <Are className="title">are</Are>
        </Reveal>
      </Block>
      <Block>
        <BarReveal delay={isMobile ? 300 : 1000}>
          {barImage && <Bar src={barImage} crossOrigin="anonymous" alt="we-are-bar" />}
        </BarReveal>
        {blocks.map((block, index) => (
          <Fragment key={index}>
            <Reveal delay={(isMobile ? 0 : 250) * (index + 1)}>
              <Title>{block.titulo}</Title>
            </Reveal>
            <Reveal delay={(isMobile ? 100 : 500) * (index + 1)}>
              <Paragraph>{block.cuerpo}</Paragraph>
            </Reveal>
          </Fragment>
        ))}
      </Block>
    </WeAreContainer>
  );
};

export default WeAre;
