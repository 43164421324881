import styled, { css } from "styled-components";
import Reveal from "../../../../components/Reveal/Reveal";
import { NAVBAR_HEIGHT } from "../../../../utils/constants";

export const WeAreContainer = styled.div`
  width: 100%;
  height: 100%;
  min-height: ${`calc(100vh - ${NAVBAR_HEIGHT}px)`};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0 172px;
  padding-top: ${NAVBAR_HEIGHT}px;
  background-color: #fff;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    padding: 0 128px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    grid-template-columns: 1fr;
    grid-row-gap: 32px;
    padding: 64px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    padding: 64px 32px;
  }
`;

export const Block = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  text-align: left;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    &:nth-child(1) {
      flex-direction: row;
      align-items: center;
      gap: 48px;
    }

    &:nth-child(2) {
      justify-content: flex-start;
      align-items: center;
      text-align: center;
    }
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.sm}px) {
    &:nth-child(1) {
      gap: 16px;
    }
  }
`;

export const Figure = css`
  position: absolute;
  z-index: 0;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    display: none;
  }
`;

export const FigureMobile = css`
  position: absolute;
  z-index: 0;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    display: none;
  }
`;

export const BarRevealMobile1 = styled(Reveal)`
  ${FigureMobile}
  top: 0;
  right: 25%;
`;

export const BarRevealMobile2 = styled(Reveal)`
  ${FigureMobile}
  bottom: -50px;
  right: 5%;
`;

export const BarMobile1 = styled.img`
  width: 200px;
`;

export const BarMobile2 = styled.img`
  width: 140px;
`;

export const Background = styled.img`
  ${Figure}
  top: calc(50% + 48px);
  left: -104px;
  transform: translateY(-50%) scale(0.9);
`;

export const BarReveal = styled(Reveal)`
  ${Figure}
  bottom: 0;
  right: 128px;
`;

export const Bar = styled.img`
  transform: translateY(50%);
`;

export const We = styled.h1`
  font-size: 300px;
  line-height: 230px;
  font-weight: 400;
  font-family: "VrodasFontTitle";
  color: #0f071c;
  margin: 0;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 240px;
    line-height: 200px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 120px;
    line-height: 120px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 64px;
    line-height: 64px;
  }
`;

export const Are = styled(We)`
  padding-left: 64px;
  margin-top: -64px;
  z-index: 1;

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    padding: 0;
    margin: 0;
  }
`;

export const Title = styled.p`
  font-size: 28px;
  line-height: 32px;
  color: #0f071c;
  margin: 12px 0;
  z-index: 1;
  white-space: pre-line;
  font-weight: 700;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 24px;
    line-height: 28px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 21px;
    line-height: 25px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 18px;
    line-height: 22px;
  }
`;


export const Paragraph = styled(Title)`
  font-size: 22px;
  line-height: 28px;
  font-weight: 400;

  @media (max-width: ${({ theme }) => theme.breakpoints.lg}px) {
    font-size: 20px;
    line-height: 24px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.md}px) {
    font-size: 18px;
    line-height: 22px;
  }

  @media (max-width: ${({ theme }) => theme.breakpoints.xs}px) {
    font-size: 15px;
    line-height: 18px;
  }
`;
